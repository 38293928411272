<template>
  <div>
    <div v-if="currentLocale === 'es'">
      <h2 data-v-35bd2db0="">
        INFORMACIÓN LEGAL
      </h2>
      <b-card-text data-v-35bd2db0="">
        Las presentes Condiciones Generales de Uso y Contratación (en adelante las “Condiciones”) regulan la
        adquisición y el uso de los productos y servicios que {{ translate(community.name) }} (según dicho término se
        define
        a continuación) pone a disposición de sus usuarios y/o clientes, ya sea a través de acceso Web, en el
        portal de Internet <a data-v-35bd2db0="" href="https://nectios.com/">https://nectios.com/</a> (en
        adelante, el “Sitio Web” o la “Web” indistintamente), a través de dispositivos iOS o Android, en línea o
        fuera de línea, así como a través de la aplicación de escritorio (en adelante, la “Solución”). La
        utilización de la Web y de la Solución le atribuye la condición de usuario (en adelante, el “Usuario”) y
        la contratación de los Servicios, tal y como éstosse definen más adelante, le atribuye la condición de
        cliente (en adelante, el “Cliente”) y ambos implican la aceptación de las presentes Condiciones. Algunos
        servicios o funcionalidades de la Solución pueden estar sometidos a condiciones particulares que, en su
        caso, modifican o complementan las presentes Condiciones y que se entenderán aceptadas por el Usuario y/o
        el Cliente al inicio de la prestación de dicho servicio o de la utilización de la correspondiente
        funcionalidad (en adelante, las Condiciones Particulares”).{{ translate(community.name) }} se reserva el derecho
        de
        modificar, en cualquier momento y sin previo aviso, el contenido y configuración de la Web y de la
        Solución, así como de los servicios ofrecidos. Los Usuariosy Clientes deben leer atentamente las presentes
        Condiciones. Al hacer clic indicando su aceptación, al aceptar una Orden de Compra de los Servicios (tal y
        como estos términosse definen más adelante) o al utilizar servicios gratuitos, el Cliente y/o el Usuario
        aceptan las presentes Condiciones que rigen el uso de los Servicios. Así mismo, el registro o uso de la
        Solución implica la íntegra aceptación de las presentes Condiciones, así como de las políticas de
        privacidad de {{ translate(community.name) }} incluidas en
        <a data-v-35bd2db0=""
          href="http://nectios.com/my/legal-center/?legal=privacy_policy">http://nectios.com/my/legal-center/</a>
        (en adelante, la “Política de Privacidad”). Por último, en el supuesto de que un Cliente contrate los
        Servicios a través de un distribuidor autorizado de {{ translate(community.name) }}, le serán de aplicación las
        presentes Condiciones Generales, con excepción de los aspectos relativos a pago, facturación,
        notificaciones y, en su caso, servicios de asistencia técnica que, en virtud del acuerdo comercial
        existente entre el distribuidor y el Cliente, no le son de aplicación.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        SERVICIOS
      </h2>
      <h3 data-v-35bd2db0="">
        Descripción de los Servicios
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} pone a disposición del Cliente, a través de la Solución, un conjunto de
        servicios de
        gestión de equipos comerciales descrito en detalle en la dirección
        <a data-v-35bd2db0="" href="http://nectios.com">http://nectios.com</a> o en otra URL similar que pudiera
        proporcionar {{ translate(community.name) }} (en adelante, los “Servicios”). Los Servicios se ofrecen en línea a
        través de la aplicación Web, a través de dispositivos móviles, tanto fuera de línea como en línea. La
        Solución permitirá el uso de una interfaz basada en diferentes aplicaciones tablet, web y móvil, así como
        el cifrado, la transmisión, el acceso y el almacenamiento de datos. Los Servicios se ofrecen en varias
        ediciones y paquetes que pueden diferir notablemente en sus funcionalidades. Consulte nuestro sitio Web
        para conocer las diferencias entre las distintas ediciones. No se puede acceder a ni utilizar los
        Servicios con el fin de controlar su disponibilidad, rendimiento o funcionalidad, ni para ningún otro
        propósito de evaluación comparativa o fin competitivo
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Datos en la prestación de los Servicios
      </h3>
      <b-card-text data-v-35bd2db0="">
        La Solución permite al Cliente procesar la información basada en (i) los horarios de actividad comercial
        del Usuario, (ii) su ubicación geográfica, (iii) las comunicaciones recibidas o enviadas por un Usuario,
        (iv) fecha, duración e interlocutor de las llamadas telefónicas recibidas o realizadas, (v) los comandos
        de voz enviados a la Solución, (vi) fotografías facilitadas por el Usuario, (vii) detalles de las
        vídeollamadas mantenidas a través de soluciones integradas en la plataforma, como por ejemplo la hora,
        duración y contenido de dichas llamadas, que podrá incluir la grabación íntegra de las mismas,
        independientemente de que se hayan mantenido con la opción de vídeo (cámara) activado o no; así como otra
        información relacionada con su actividad comercial y que encontrará actualizada en todo momento en la
        Política de Privacidad de {{ translate(community.name) }} en el link
        <a data-v-35bd2db0="" href="https://nectios.com/es/info/privacidad/">https://nectios.com/es/info/privacidad/</a>

        (en adelante los “Datos de los Usuarios”). El Cliente, y en su caso el Usuario, podrá darse de baja de
        estas funcionalidades en cualquier momento mediante la edición de la configuración a nivel de dispositivo
        o poniéndose en contacto con {{ translate(community.name) }}. Por contra, el Cliente podrá activar nuevas
        funcionalidades poniéndose en contacto con {{ translate(community.name) }}, bajo un nuevo presupuesto, debiendo
        informar de ello al Usuario. Así mismo, y para la efectiva prestación de los Servicios y funcionamiento de
        la Solución, el Cliente y/o cada Usuario podrán enviar a la Solución aquellos datos, informaciones o
        materiales que consideren oportuno, incluyendo datos, informaciones o materiales de terceros, en especial
        los clientes o clientes potenciales del Cliente (estos últimos, en adelante, los “Datos de Terceros”). Los
        Datos de los Usuarios y los Datos de Terceros serán denominados conjuntamente como los “Datos”. A efectos
        aclaratorios, los Datos incluirán cualquier dato volcado a la Solución (exceptuando los datos de contacto
        y facturación del propio Cliente). En cualquier caso, los datos enviados a la aplicación por parte del
        Cliente y/o el Usuario, deberán cumplir con las presentes Condiciones y Política de Privacidad.
        {{ translate(community.name) }} sólo compartirálos Datos en los términos indicados en la Política de Privacidad.
        Así
        mismo, el Cliente declara y garantiza que tiene la correspondiente autorización para gestionar los Datos
        de Terceros mediante el uso de la Solución. Mediante la marcación de la casilla habilitada a tales
        efectos, el Cliente acepta recibir periódicamente por parte de {{ translate(community.name) }}, comunicaciones
        de
        marketing relativas a los productos y servicios de {{ translate(community.name) }}, así como newsletters de
        {{ translate(community.name) }} con noticias y novedades sobre ventas,tecnología y el sector del Cliente. El
        Cliente
        podrá notificar a {{ translate(community.name) }}, en cualquier momento y por correo electrónico a la dirección
        <a data-v-35bd2db0="" :href="email">newsletter{{ translate(community.name) }}.tech</a> , su deseo de no recibir
        una o
        ambas comunicaciones.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Modificaciones
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} podrá realizar los cambios, modificaciones y ajustes que considere razonables en
        los
        Servicios cuando lo estime oportuno. En caso de realizar un cambio sustancial en los Servicios,
        {{ translate(community.name) }} deberá comunicarlo previamente al Cliente.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        OBLIGACIONES DEL CLIENTE
      </h2>
      <h3 data-v-35bd2db0="">
        Cumplimiento de las Condiciones
      </h3>
      <b-card-text data-v-35bd2db0="">
        Por la presente, el Cliente y el Usuario se obligan a utilizar los Servicios de acuerdo con los términos
        establecidos en las presentes Condiciones. {{ translate(community.name) }} puede ofrecer, según considere
        oportuno,
        aplicaciones, características o funciones adicionales a través de los Servicios, cuyo uso puede estar
        supeditado a Condiciones Particulares que deberán ser aceptadas por el Cliente y, en su caso, por el
        Usuario.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Condiciones de uso y límites
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Cliente y el Usuario cumplirán con las condiciones de uso y límites relativos al uso de los Servicios
        que establezca {{ translate(community.name) }} en cada momento, incluidos, sin limitaciones: (i) requisitos
        técnicos
        y de implementación de la Solución; (ii) directrices de tratamiento de las marcas titularidad de
        {{ translate(community.name) }} para los Servicios; (iii) términos de uso y política de privacidad del Sitio Web
        y de
        la Solución y (iv) requisitos de {{ translate(community.name) }} para protección de datos y seguridad.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Uso permitido y restricciones de uso
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Cliente y el Usuario aceptan utilizar los Servicios exclusivamente de conformidad con las presentes
        Condiciones y la legislación aplicable. Asimismo, el Cliente y el Usuario se comprometen a no involucrarse
        en ninguna actividad que interfiera con los Servicios o losservidores o redes conectados a los Servicios o
        que pueda interrumpirlos. Además de regirse por estas Condiciones, el uso de determinados servicios
        específicos de {{ translate(community.name) }} por parte del Cliente estará regido por las Condiciones
        Particulares
        que se presentan al Cliente cuando éste se registra o accede a los Servicios en cuestión, incorporándose
        específicamente las mismas en el momento de aceptación por parte del Cliente.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        A menos de que {{ translate(community.name) }} lo acuerde específicamente por escrito, ni el Cliente ni el
        Usuario
        podrán llevar a cabo ninguna de las acciones que se describen a continuación, debiendo el Cliente
        asegurarse de que el Usuario cumpla con lo aquí especificado. Asimismo, tanto el Cliente como el Usuarios
        deberán realizar todos los esfuerzos razonables para asegurarse de que ningún tercero las lleve a cabo:
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        (i) utilizar la Solución de forma que pueda dañar, deshabilitar, sobrecargar o deteriorar la misma, ni
        utilizarla de forma que pueda interferir en el uso y disfrute de la Solución por parte de terceros; (ii)
        eliminar, ocultar o alterar avisos de derechos de autor, marcas u otros avisos de derechos de propiedad,
        que contenga la Solución;(iii) enviar correos basura o mensajes duplicados o no deseados que infrinjan la
        normativa aplicable; (iv) enviar o almacenar material ilícito, obsceno, amenazante, difamatorio, ilegal o
        agravioso, incluido material perjudicial para los niños o que infrinja los derechos de privacidad de
        terceros; (v) enviar o almacenar material que contenga software con virus, gusanos, troyanos o códigos,
        archivos, secuencias de comandos, agentes o programas informáticos dañinos; (vi) interferir o afectar a la
        integridad o rendimiento de los Servicios o la Solución, o los datos que contiene; o (vii) intentar
        obtener acceso no autorizado a la Solución o los sistemas o redes relacionados.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Administración de los Servicios por parte del Cliente
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Cliente recibirá un nombre y una contraseña que deberá utilizar a efectos de administración de las
        cuentas de Cliente. El Cliente tiene la responsabilidad de mantener la confidencialidad de dicha
        contraseña y cuenta, para lo cual designará a los empleados que dispondrán de autorización para acceder a
        la cuenta y limitará el alcance de dicha autorización al cumplimiento de las tareas asignadas y para todas
        las actividades que se realicen en el marco de la cuenta del Cliente. El Cliente acepta notificar de forma
        inmediata a {{ translate(community.name) }} sobre cualquier uso o acceso no autorizado a los Servicios, la
        Solución,
        a la contraseña o cuenta del Cliente o cualquier otro tipo de vulneración de la seguridad.
        {{ translate(community.name) }} no podrá responsabilizarse ni se responsabilizará de pérdida o daño alguno
        derivado
        del incumplimiento de las obligaciones de seguridad por parte del Cliente. El Cliente reconocey acepta que
        {{ translate(community.name) }} no se responsabilizará en forma alguna ni bajo circunstancia alguna de ninguna
        acción
        u omisión por parte del Cliente o de cualquier Usuario, incluidos los daños de cualquiertipo resultantes
        de dichas acciones u omisiones.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Responsabilidad del Cliente y consentimiento del Usuario
      </h3>
      <b-card-text data-v-35bd2db0="">
        El administrador del Cliente puede tener la capacidad de acceder, supervisar, utilizar o divulgar los
        Datos de los Usuarios y los Datos de Terceros dentro de las cuentas de los Usuarios vinculados al Cliente,
        configurándose el Cliente como responsable del tratamiento de estos datos. Es responsabilidad exclusiva
        del Cliente cumplir con cuantas obligaciones se deriven de su condición de responsable del tratamiento,
        con entera indemnidad para {{ translate(community.name) }}.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Así mismo, el Cliente y el Usuario son conocedores de que, en el supuesto que el Cliente haya dado de alta
        esta funcionalidad, cualquier Usuario vinculado con el Cliente puede acceder y supervisar cualquier dato
        dentro de las cuentas de cualquier otro Usuario vinculado con el mismo Cliente, ya que cualquier Usuario
        vinculado con el Cliente puede tener visibilidad de las cuentas de los demás Usuarios vinculados con el
        mismo Cliente. El Cliente garantiza que cuenta con el consentimiento expreso de los Usuarios para permitir
        dicha funcionalidad. El Cliente, y en su caso el Usuario, podrá darse de baja de estas funcionalidades en
        cualquier momento mediante la edición de la configuración a nivel de dispositivo o poniéndose en contacto
        con {{ translate(community.name) }}.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Uso no autorizado
      </h3>
      El Cliente llevará a cabo sus mejores esfuerzos para impedir el uso no autorizado de los Servicios y para
      cancelar usos no autorizados. El Cliente informará de inmediato a {{ translate(community.name) }} de cualquier uso
      o
      acceso no autorizado al Servicio del que tenga conocimiento.
      <b-card-text data-v-35bd2db0="" />
      <h3 data-v-35bd2db0="">
        Condiciones aplicables al Usuario
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Cliente reconoce que será responsable de toda actividad que se produzca en su cuenta de Cliente y que
        sus Usuarios están sujetos a las presentes Condiciones por lo que respecta a cada uno de los componentes
        de los Servicios, así como a las Condiciones Particulares que puedan ser de aplicación a determinados
        Servicios.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        En el caso de que el Cliente tenga noticia de alguna infracción de las presentes Condiciones o de las
        Condiciones Particulares en aquellos extremos aplicables a los Usuarios, o de otras condiciones de los
        Servicios o políticas aplicables por parte de un Usuario, el Cliente acepta (i) notificárselo a
        {{ translate(community.name) }} tan pronto como sea posible; y (ii) suspender o cancelar de manera inmediata la
        cuenta de dicho Usuario a menos que {{ translate(community.name) }} consienta lo contrario por escrito (incluso
        a
        través del correo electrónico). A petición de {{ translate(community.name) }}, el Cliente suspenderá o cancelará
        de
        forma inmediata cualquier Cuenta del Usuario o acceso de administrador al Servicio como respuesta a una
        infracción de los términos de uso o política aplicables por parte de un Usuario. {{ translate(community.name) }}
        se reserva el derecho, a su discreción, de suspender o cancelar la Cuenta de Usuario de cualquier Usuario.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Información y datos de la cuenta
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} no es propietario de los datos, informaciones o materiales que envíe el Cliente
        y/o
        el Usuario a los Servicios y la Solución durante su uso (los “Datos de los Usuarios y los Datos de
        Terceros”). El Cliente, y no {{ translate(community.name) }}, será el único responsable de la exactitud,
        calidad,
        integridad, legalidad, fiabilidad, idoneidad y derechos de propiedad intelectual y/o industrial en el uso
        de los Datos y {{ translate(community.name) }} no será responsable de la eliminación, corrección, destrucción,
        daño,
        pérdida o error surgido durante el almacenamiento de los Datos, y en general, de cualquier dato subido a
        la Solución.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Siempre que el Cliente lo solicite en el momento de la resolución de la contratación de los Servicios,
        {{ translate(community.name) }} pondrá a disposición del Cliente un archivo con los Datos (titularidad del
        Cliente)
        durante los treinta (30) días posteriores a la extinción de los Servicios
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Interacción con terceros
      </h3>
      <b-card-text data-v-35bd2db0="">
        Durante el uso de los Servicios, el Cliente puede compartir correspondencia, adquirir bienes o servicios o
        participar en promociones de anunciantes o patrocinadores que muestren sus bienes y/o servicios a través
        de los Servicios.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Cualquiera de estas actividades y cualquier condición, manifestación o garantía asociada a dichas
        actividades es exclusiva entre el Cliente y dichos terceros. {{ translate(community.name) }} y sus
        distribuidores no
        tienen responsabilidad u obligación alguna derivada de dicha correspondencia, adquisición o promoción
        entre el Cliente y dichos terceros.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} no respalda ningún sitio de Internet al que se acceda a través de los Servicios.
        {{ translate(community.name) }} ofrece estos enlaces al Cliente para su comodidad y en ningún caso
        {{ translate(community.name) }} o susdistribuidores serán responsables del contenido, los productos u otros
        materiales disponibles en dichos enlaces.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} ofrece los Servicios al Cliente de conformidad con las presentes Condiciones y,
        cuando corresponda, conforme a las Condiciones Particulares. No obstante, el Cliente admite que otros
        proveedores de software, hardware o servicios adicionales pueden requerir la aceptación por parte del
        Cliente de otra licencia diferente o adicional u otras condiciones antes de poder utilizar o acceder a
        dicho software, hardware o servicios.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        FACTURACIÓN Y PAGO
      </h2>
      <h3 data-v-35bd2db0="">
        Periodos de prueba gratuita
      </h3>
      <b-card-text data-v-35bd2db0="">
        En aquellas contrataciones a las que se apliquen periodos iniciales de prueba gratuitos conforme a lo
        dispuesto en la Orden de Compra (según dicho término se define en la condición 4.2 siguiente) no se
        realizará ningún cargo mientras dure dicho periodo. Si el Cliente decide cancelar los Servicios antes de
        finalizar el periodo de prueba, no se le realizará ningún cargo.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Forma de pago
      </h3>
      <b-card-text data-v-35bd2db0="">
        Todos los pagos se realizarán en la forma indicada en la orden de compra de servicios aceptada por el
        Cliente (en adelante, la “Orden de Compra”). El Cliente realizará el pago de los importes indicados en la
        Orden de Compra (en adelante, los “Importes”).
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        LosImportes, una vezfacturados, deberán abonarse al principio del periodo en el que el Cliente recibe los
        Servicios y con la periodicidad acordada en la Orden de Compra. No se podrán cancelar las obligaciones de
        pago ni reembolsar las cantidades ya pagadas. El Cliente tiene la obligación de pagar todas las licencias
        de Usuario solicitadas para todo el periodo de licencia establecido en la Orden de Compra (el “Periodo de
        Licencia”), tanto si las mismas están en uso como si no.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Facturas
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Cliente se obliga a ofrecer información completa y precisa de facturación y de contacto a
        {{ translate(community.name) }}. Esta información incluye el nombre de la compañía, número de cuenta corriente,
        tarjeta de crédito (en su caso) u otra forma de pago aplicable, dirección postal, dirección de correo
        electrónico, nombre y número de teléfono de un contacto autorizado para la facturación, el número de
        identificación del impuesto sobre el valor añadido intracomunitario (en su caso) y datos del administrador
        del Cliente.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Cliente se compromete a actualizar esta información en un plazo de diez (10) días tras cualquier
        modificación. Si la información de contacto que ha ofrecido esfalsa, fraudulenta o no está actualizada,
        {{ translate(community.name) }} se reserva el derecho de cancelar el acceso al Servicio, así como de emprender
        cualquier otra medida legal que estime conveniente
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Si el Cliente considerase que su factura no es correcta, deberá ponerse en contacto con
        {{ translate(community.name) }}o su distribuidor por escrito, en un período máximo de siete (7) días a partir de
        la
        fecha de dicha factura, para revisar conjuntamente dicha factura y, en su caso, poder recibir una
        rectificación
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Modificación del número de licencias
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Cliente podrá solicitar licencias adicionales en cualquier momento, que estarán sujetas a las presentes
        Condiciones y, en su caso, a las Condiciones Particulares.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Asimismo, el Cliente podrá reducir el número de licencias para Usuarios mediante la remisión de un correo
        electrónico a {{ translate(community.name) }} con una antelación mínima de treinta (30) días hábiles antes de
        lafecha
        del siguiente Período de Licencia.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Modificación del plan de Servicios
      </h3>
      <b-card-text data-v-35bd2db0="">
        En el caso que el Cliente solicite una mejora (o upgrade) de su actual plan de Servicios, las condiciones
        específicas aplicables como consecuencia de dicha mejora serán las establecidas en la Orden de Compra y
        será prorrateada proporcionalmente por el período restante del Periodo de Licencia.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Por el contrario, el Cliente no podrá solicitar una reducción (o downgrade) de su plan de Servicios
        durante el Periodo de Licencia, siendo únicamente aplicable una vez finalizado dicho Periodo de Licencia.
        La solicitud de una reducción deberá realizarse también mediante la remisión de un correo electrónico a
        {{ translate(community.name) }} con una antelación mínima de treinta (30) días hábiles antes de la finalización
        del
        Período de Licencia.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Tarifas adicionales
      </h3>
      <h4 data-v-35bd2db0="">
        Tarifa adicional de almacenamiento de datos
      </h4>
      <b-card-text data-v-35bd2db0="">
        El espacio de almacenamiento de datos que {{ translate(community.name) }} le ofrece de forma gratuita al Cliente
        es
        de1GB en base de datos para el conjunto de Licencias de Usuario que el Cliente tenga contratadas en
        cualquier edición o paquete de {{ translate(community.name) }} y 5MB adicionales por cada Licencia de Usuario de
        cualquier edición o paquete de {{ translate(community.name) }}.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Si la cantidad de almacenamiento de disco requerida por el Cliente superara los límites establecidos en el
        párrafo anterior, {{ translate(community.name) }} se reserva el derecho de cobrar al Cliente la tarifa de
        almacenamiento vigente en ese momento. {{ translate(community.name) }} intentará comunicar al Cliente, en la
        medidade
        lo posible, cuando el almacenamiento medio utilizado por licencia alcance el noventa por ciento (90%)
        aproximadamente del límite indicado. No obstante, si {{ translate(community.name) }} no le notifica esta
        situación,
        ello no exime al Cliente de su responsabilidad y obligación de pago ante los cargos de almacenamiento de
        datos adicionales
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} se reserva el derecho de establecer o modificar en cualquier momento, su
        proceder
        habitual y los límites en relación con el almacenamiento de datos. {{ translate(community.name) }} publicará en
        su
        página web su política de almacenamiento de datos y los límites vigentes en cada momento.
      </b-card-text>
      <h4 data-v-35bd2db0="">
        Tarifa adicional de almacenamiento de archivos
      </h4>
      <b-card-text data-v-35bd2db0="">
        El espacio de almacenamiento de archivos que {{ translate(community.name) }} le ofrece de forma gratuita al
        Cliente
        esde 2,5GB para el conjunto de Licencias de Usuario que el Cliente tenga contratadas en cualquier edicióno
        paquete de {{ translate(community.name) }} y 250MB adicionales por cada Licencia de Usuario de cualquier edición
        o
        paquete de {{ translate(community.name) }}.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Si la cantidad de almacenamiento de archivos requerida por el Cliente superara los límites establecidos en
        el párrafo anterior, {{ translate(community.name) }} se reserva el derecho de cobrar al Cliente la tarifa de
        almacenamiento vigente en ese momento. {{ translate(community.name) }} intentará comunicar al Cliente, en la
        medidade
        lo posible, cuando el almacenamiento medio utilizado por licencia alcance el noventa por ciento (90%)
        aproximadamente del límite indicado. No obstante, si {{ translate(community.name) }} no le notifica esta
        situación,
        ello no exime al Cliente de su responsabilidad y obligación de pago ante los cargos de almacenamientode
        archivos adicionales.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} se reserva el derecho de establecer o modificar en cualquier momento, su
        proceder
        habitual y los límites en relación con el almacenamiento de archivos. {{ translate(community.name) }} publicará
        en su
        página web su política de almacenamiento de archivos y los límites vigentes en cada momento.
      </b-card-text>
      <h4 data-v-35bd2db0="">
        Tarifa adicional de servicios de geolocalización
      </h4>
      <b-card-text data-v-35bd2db0="">
        El volumen de peticiones de geolocalización que {{ translate(community.name) }} ofrece de forma gratuita al
        Cliente
        esde 20.000 al mes por el conjunto de Licencias de Usuario que el Cliente tenga contratadas en cualquier
        edición o paquete de {{ translate(community.name) }}.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Si el volumen de peticiones de geolocalización requerido por el Cliente superara los límites establecidos
        en el párrafo anterior, {{ translate(community.name) }} se reserva el derecho de cobrar al Cliente la tarifa de
        geolocalización vigente en ese momento. {{ translate(community.name) }} intentará comunicar al Cliente, en la
        medidade lo posible, cuando el volumen de peticiones de geolocalización utilizado por el Cliente alcance
        el noventa por ciento (90%) aproximadamente del límite indicado. No obstante, si {{ translate(community.name) }}
        no
        le notifica esta situación, ello no exime al Cliente de su responsabilidad y obligación de pago ante los
        cargos de geolocalización adicionales
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} se reserva el derecho de establecer o modificar en cualquier momento, su
        proceder
        habitual y los límites en relación con las peticiones de geolocalización. {{ translate(community.name) }}
        publicará
        en su página web su política de peticiones de geolocalización y los límites vigentes en cada momento.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Impago
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} se reserva el derecho a suspender o extinguir las presentes Condiciones y, en su
        caso,las Condiciones Particulares que resulten de aplicación y, por consiguiente, el acceso a los
        Servicios por parte del Cliente si incurre en retrasos en los pagos por un período superior a diez (10)
        días desde su vencimiento.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Las cuentas con retrasos en los pagos están sujetas a un interés mensual del 1,5% aplicable sobre el saldo
        pendiente, además de todos los gastos necesarios para su cobro. Este interés no podrá ser fraccionado y un
        solo día de retraso implicará la aplicación de la totalidad de los intereses.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Si el Cliente o {{ translate(community.name) }} finalizan el acceso al Servicio, el Cliente estará obligado a
        abonar
        el saldo pendiente de su cuenta.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} se reserva el derecho a imponer una tarifa de reconexión en caso de que el
        Cliente
        hayasido suspendido y posteriormente haya solicitado el acceso al Servicio.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        IMPUESTOS
      </h2>
      <b-card-text data-v-35bd2db0="">
        Los importes establecidos por los Servicios no incluyen impuestos, tasas u otros recargos similares de
        ninguna naturaleza, incluidos sin limitarse a ellos, impuestossobre el valor añadido, de ventas, de uso o
        retenciones, establecidos por la legislación aplicable (en forma conjunta, “Impuestos”). El Cliente será
        responsable del pago de los Impuestos y no podrá realizar pagos a {{ translate(community.name) }} por los
        Servicios
        con deducciones de Impuestos incluidas.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        SUSPENSIÓN E INTERRUPCIÓN DE LOS SERVICIOS
      </h2>
      <h3 data-v-35bd2db0="">
        De Cuentas de Usuario por parte de {{ translate(community.name) }}
      </h3>
      <b-card-text data-v-35bd2db0="">
        En caso de incumplimiento de las presentes Condiciones por el Cliente, o en el evento en que, previo
        requerimiento por parte de {{ translate(community.name) }}, el Cliente no hubiera adoptado las medidas
        necesarias
        para su cumplimiento en un plazo de diez (10) días, {{ translate(community.name) }} se reserva el derecho a
        suspender
        los Servicios, sin perjuicio de lo establecido en el apartado 12 de las presentes Condiciones.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Adicionalmente, si {{ translate(community.name) }} tiene conocimiento de algún incumplimiento de las presentes
        Condiciones por parte del Usuario, podrá solicitar al Cliente la suspensión de la Cuenta de Usuario en
        cuestión. En caso de que el Cliente no satisfaga dicha solicitud de {{ translate(community.name) }}, será
        {{ translate(community.name) }}
        quien podrá llevar a cabo la suspensión de la Cuenta de Usuario. La duración de cualquier suspensiónse
        prolongará hasta que el Usuario en cuestión haya subsanado el incumplimiento causante de la misma.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Disponibilidad y Acceso a los Servicios.
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} proporcionará disponibilidad y acceso continuo a los Servicios, salvo en los
        eventos
        enlos que, por circunstancias ajenas a su control, deban realizarse suspensiones o interrupciones de los
        mismos, que serán debidamente informadas al Cliente. Dichas interrupciones podrán deberse a terceros de
        los que depende la prestación de los Servicios, tales como los prestadores de servicios de hosting y de
        firma digital, entre otros proveedores de servicios externos. En estos casos {{ translate(community.name) }}no
        incurrirá en responsabilidad alguna como consecuencia de la suspensión o interrupción de los Servicios.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} realiza sus mejores esfuerzos para llevar a cabo las tareas de mantenimiento y
        mejoraen la Solución de modo que no suponga la interrupción de los Servicios. Sin embargo, para el caso
        enque para llevar a cabo dichas tareas sea necesaria la interrupción temporal de los Servicios,
        {{ translate(community.name) }} notificará con antelación a los Clientes y a los Usuarios de la fecha prevista
        para
        realizardichas tareas y la duración estimada de las mismas. {{ translate(community.name) }} no incurrirá en
        responsabilidad alguna como consecuencia de la suspensión o interrupción de los Servicios para la
        realización de tareas de mantenimiento y mejora en la Solución siempre que haya informado oportunamente a
        los Clientesy los Usuarios conforme a lo dispuesto en este apartado.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Por Incidencias de seguridad de urgencia
      </h3>
      <b-card-text data-v-35bd2db0="">
        Si existe una incidencia de seguridad de urgencia, {{ translate(community.name) }} podrá proceder a la
        suspensión
        automática del uso de la cuenta de Usuario. La suspensión tendrá la duración y el alcance mínimos
        necesarios para evitar o resolver la incidencia de seguridad de urgencia. Si, por cualquier motivo,
        {{ translate(community.name) }} suspende una cuenta de Usuario sin avisar previamente al Cliente,
        {{ translate(community.name) }} indicará el motivo de la suspensión al Cliente tan pronto como sea
        razonablemente
        posible, si así lo requiere este último.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        DERECHOS DE PROPIEDAD INTELECTUAL Y/O INDUSTRIAL
      </h2>
      <b-card-text data-v-35bd2db0="">
        A excepción de lo establecido de forma expresa en el presente documento, las presentes Condiciones no
        garantizan a ninguna de las partes ningún derecho, implícito o no, sobre el contenido o sobre cualquier
        derecho de propiedad intelectual y/o industrial de la otra parte. En este sentido,
        {{ translate(community.name) }} posee todos los derechos de propiedad intelectual y/o industrial de los
        Servicios.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} y sus proveedores mantendrán la propiedad de todo derecho, título e interés
        incluyendo, de forma no limitativa, todos los derechos de propiedad intelectual y/o industrial (en virtud
        de lo definido más adelante) relacionados con los Servicios y la Solución, así como de cualquier trabajoo
        mejora derivados de ésta, incluyendo, sin ánimo limitativo, cualquier software, tecnología, información,
        contenido, materiales, directrices y documentación. Ni los Clientes ni los Usuarios adquirirán derecho,
        título, interés o contenido alguno, exceptuando los derechos de uso limitado establecidos de forma expresa
        en las presentes Condiciones y/o en las Condiciones Particulares. Todo derecho que no se conceda
        expresamente en este documento se considerará no otorgado.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} no es propietario del contenido de terceros utilizado como parte de los
        Servicios,
        incluido el contenido de las comunicaciones que aparecen en los Servicios. El título y los derechos de
        propiedad intelectual y/o industrial del contenido al que se accede a través de los Servicios pertenecen
        al propietario del contenido en cuestión y pueden estar protegidos por la ley de propiedad intelectual u
        otras leyes aplicables.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Cliente acepta no llevar a cabo ni permitir a otros que lleven a cabo las siguientes acciones: (i)
        adaptar, traducir o modificar la Solución; (ii) descifrar, descompilar, desmontar, aplicar ingeniería
        inversa u otro tipo de intento de descubrir el código fuente o de objetos del software de la Solución,
        salvo si lasleyes aplicables prohíben dichaslimitaciones; (iii) copiar la Solución; (iv) utilizar los
        Servicios para actividades de alto riesgo; ni (v) otorgar bajo licencia, prestar, vender, alquilar o
        arrendar los Servicios o cualquier componente de éstos
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Cualquier código fuente de tercerosincluido en cada una de las aplicaciones de los Serviciossólo podrá
        utilizarse conjuntamente con dicha aplicación de los Servicios, cuyo uso estará sujeto a las presentes
        Condiciones y/o en las Condiciones Particulares.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        QUEDA PROHIBIDO UTILIZAR, COPIAR, TRANSFERIR Y MODIFICAR LA SOLUCIÓN (INCLUYENDO LOS DERECHOS DE PROPIEDAD
        INTELECTUAL Y/O INDUSTRIAL SOBRE LA MISMA) O CUALQUIER PARTE DE LA MISMA, EXCEPTO EN LOS CASOS EN QUE LAS
        PRESENTES CONDICIONES LO PERMITAN DE FORMA EXPRESA.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        7.1 Marcas y títulos distintivos
      </h3>
      <b-card-text data-v-35bd2db0="">
        A los efectos de las presentes Condiciones y de las Condiciones Particulares que puedan ser de aplicación,
        se entienden por “Marcas y Títulos Distintivos” los nombres comerciales, marcas comerciales, marcas de
        servicio, logotipos, nombres de dominio y otros elementos de marca distintivos de cada parte,
        respectivamente, protegidos en cada momento por dicha parte. Ninguna de las partes puede mostrar o
        utilizar los Marcas y Títulos Distintivos de la otra más allá de lo permitido en estas Condiciones y/o en
        las Condiciones Particulares sin el consentimiento previo por escrito de la otra parte. Excepto en la
        medida en que se indique expresamente, ninguna de las partes concederá ni la otra adquirirá ningún
        derecho, título ni interés incluido, sin limitación, cualquier licencia implícita de cualquier Marcas y
        Títulos Distintivos de la primera parte. Todos los derechos que no se concedan expresamente se
        considerarán no otorgados. Todo uso de los Marcas y Títulos Distintivos del Cliente por parte de
        {{ translate(community.name) }}, se realizará en beneficio del Cliente; y todo uso de los Marcas y Títulos
        distintivos de {{ translate(community.name) }} por parte del Cliente, se realizará en beneficio de
        {{ translate(community.name) }}. El Cliente no infringirá ni colaborará con otros en la infracción de las Marcas
        y
        Títulos Distintivos de {{ translate(community.name) }} o el registro de los mismos, así como tampoco intentará
        registrar Marcas y Títulos Distintivos de {{ translate(community.name) }} o nombres de dominio muy parecidos a
        los de
        {{ translate(community.name) }} o que puedan inducir a equívocos.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Limitación en el uso de Marcas y Títulos Distintivos
      </h3>
      <b-card-text data-v-35bd2db0="">
        Las partes podrán revocar el derecho de la otra parte a usar sus Marcas y Títulos Distintivos conforme a
        estas Condiciones mediante aviso por escrito a la otra parte, que tendrá que cesar inmediatamente en su
        uso.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Software de código abierto (Open Source Software) y bibliotecas de terceros
      </h3>
      <b-card-text data-v-35bd2db0="">
        La Web y la Solución pueden incluir herramientas de software de código abierto o bibliotecas de terceros.
        El software de código abierto y/o las bibliotecas de terceros se regirán por sus propios términos y
        condiciones y el Usuario estará sujeto a los términos y condiciones de la licencia correspondiente en
        relación con el uso de dicho software o bibliotecas. El Usuario acepta cumplir con todas las mencionadas
        licencias.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        CONFIDENCIALIDAD
      </h2>
      <b-card-text data-v-35bd2db0="">
        Cada una de las partes (i) protegerá la información confidencial de la otra parte con las mismas medidas
        de protección estándar que utiliza para proteger su propia Información confidencial; y (ii) no divulgará
        la información confidencial, excepto a los empleados y representantes, en el caso exclusivo de que sea
        necesario y de que hayan aceptado por escrito mantener las obligaciones de confidencialidad. Cada una de
        las partes (y todos los empleados y representantes a los que se haya revelado información confidencial)
        debe utilizar la información confidencial únicamente para ejercersus derechos y cumplir las obligaciones
        establecidas en estas Condiciones y/o en las Condiciones Particulares; empleando medidas razonables para
        protegerla. Cada una de las partes es responsable de las acciones de sus empleados y representantes que
        infrinjan este apartado.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Excepciones
      </h3>
      <b-card-text data-v-35bd2db0="">
        No se considera información confidencial aquella información (a) que ya sea conocida por el destinatario,
        (b) que se haga pública sin responsabilidad del destinatario, (c) que el destinatario genere de forma
        independiente o (d) que un tercero le transmita legítimamente.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Divulgación necesaria
      </h3>
      <b-card-text data-v-35bd2db0="">
        Cada una de las partes puede divulgar la información confidencial de la otra parte cuando sea requerido
        por la ley, aunque, si la ley lo permite, solo después de que (i) realice todos los esfuerzos razonables
        para informar a la otra parte; y (ii) conceda a la otra parte la oportunidad de recusar la divulgación.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        PUBLICIDAD
      </h2>
      <b-card-text data-v-35bd2db0="">
        El Cliente y el Usuario aceptan no llevar a cabo ningún tipo de anuncio público en relación con la
        existencia o el contenido de las presentes Condiciones y/o las Condiciones Particulares sin la previa
        autorización por escrito por parte de {{ translate(community.name) }}. {{ translate(community.name) }} podrá (i)
        incluir las
        Marcas y Títulos Distintivos del Cliente en presentaciones, materiales de marketing y listas de clientes,
        que incluyen,sinlimitación, listas de clientes publicadas en los sitios web de {{ translate(community.name) }} y
        capturas de pantalla de laimplementación de los Servicios por parte del Cliente; y (ii) emitir un anuncio
        público en relación con laexistencia o el contenido de estas Condiciones. A petición del Cliente,
        {{ translate(community.name) }} proporcionará aéste una muestra de dicho uso o anuncio
      </b-card-text>
      <h2 data-v-35bd2db0="">
        DURACIÓN DE LOS SERVICIOS Y RENOVACIÓN
      </h2>
      <h3 data-v-35bd2db0="">
        Duración de los Servicios
      </h3>
      <b-card-text data-v-35bd2db0="">
        El plazo de cada Servicio será el especificado en la Orden de Compra correspondiente. Salvo que en la
        Orden de Compra se indique lo contrario, los Servicios se renovarán automáticamente por períodos
        adicionales equivalentes al Período de Licencia de la Orden de Compra inicial o un año (el que sea más
        corto), a menos que cualquiera de las partes lo comunique a la otra parte por escrito con una antelación
        mínima de treinta (30) días antes de la fecha fin del Período de Licencia vigente.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Salvo lo dispuesto expresamente en la Orden de Compra correspondiente, en la renovación de los Servicios
        contratados inicialmente bajo condiciones promocionales se aplicará el precio tarifa de
        {{ translate(community.name) }}
        vigente en el momento de la renovación correspondiente.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Sin perjuicio de lo anterior, cualquier renovación en la que el volumen de Usuarios de los Servicios
        contratados o la duración de cualquiera de ellos sea inferior al Período de Licencia anterior puede dar
        como resultado un nuevo precio por Usuario y Servicio, sin tener en cuenta el precio por Usuario del
        Período de Licencia anterior.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Actualización y revisión de precios
      </h3>
      <b-card-text data-v-35bd2db0="">
        En caso de renovación de los Servicios contratados, {{ translate(community.name) }} podrá actualizar el precio
        de
        dichos Servicios al alza sin preaviso alguno en una cantidad equivalente al resultante de aplicar el
        índice de incremento del coste de vida a los precios aplicables durante el Período de Licencia anterior. A
        estos efectos, se tomará en cuenta el índice de incremento del coste de vida del país a cuya legislación
        queden sujetas las presentes Condiciones de conformidad con el Anexo I de las mismas.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} puede revisar sus precios para el siguiente Periodo de Licencia de los Servicios
        por
        encima de los importes resultantes del párrafo anterior, siempre que medie notificación por email al
        Cliente al menos treinta (30) días antes del inicio del Periodo de Licencia de los Servicios en el que se
        deba aplicar dicha revisión de precios.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Devoluciones y prorrateo
      </h3>
      <b-card-text data-v-35bd2db0="">
        En ningún caso se prorratearán Periodos de Licencia y no se realizarán devoluciones de losimportes ya
        pagados por el Cliente.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        PERÍODO DE VIGENCIA Y TERMINACIÓN DE LAS CONDICIONES
      </h2>
      <h3 data-v-35bd2db0="">
        Periodo de vigencia de las Condiciones
      </h3>
      <b-card-text data-v-35bd2db0="">
        Estas Condiciones tendrán validez desde su aceptación por primera vez por el Cliente o el Usuario o el uso
        de Servicios gratuitos por el Usuario conforme a lo indicado en la condición 1 de estas Condiciones y
        continúa hasta que todos los Servicios hayan expirado o hayan sido cancelados conforme a lo indicado en
        estas Condiciones. Sin perjuicio de lo anterior, las condiciones 7, 8, 9, 12 y 16, y los apartados de la
        condición 3 que por su naturaleza deban seguir vigentes, seguirán vigentes una vez finalizado el período
        de vigencia de las Condiciones.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        En el caso de las pruebas gratuitas, las notificaciones emitidas a través de los Servicios que indican el
        número de días restantes de la prueba gratuita constituyen el aviso por {{ translate(community.name) }} de
        terminaciónde la vigencia de estas Condiciones.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Cualquier incumplimiento de las obligaciones contraídas por el Cliente en virtud de las presentes
        Condiciones incluyendo, pero no limitándose a, el impago, pago o uso no autorizado de la Solución,
        software, producto o de los Servicios de {{ translate(community.name) }}, supondrá un incumplimiento material de
        las
        presentes Condiciones. En caso de incumplimiento de estas Condiciones por parte del Cliente,
        {{ translate(community.name) }}, bajo criterio propio, podrá suspender o cancelar la prestación de los Servicios
        al
        Cliente, suspender los Usuarios y vinculados al Cliente y sus contraseñas.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Terminación por incumplimiento
      </h3>
      <b-card-text data-v-35bd2db0="">
        Cada una de las partes puede suspender los Servicios y dar por terminadas esta Condiciones si (i) la otra
        parte incumple de forma sustancial las presentes Condiciones y no subsana el incumplimiento antes de 30
        días después de la recepción de la notificación por escrito de la parte no incumplidora, (ii) la otra
        parte cesa sus operaciones empresariales o se ve sometida a procedimientos de insolvencia y dichos
        procedimientos no se desestiman antes de noventa días o (iii) la otra parte incumple las presentes
        Condiciones de forma sustancial más de dos veces, sin perjuicio de que se subsanen dichos incumplimientos.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Efectos de la terminación
      </h3>
      <b-card-text data-v-35bd2db0="">
        En caso de terminación (i) los derechos otorgados por una parte a la otra cesarán con efecto inmediato
        (con excepción de los derechos otorgados en virtud de las condicionesindicadas en el apartado 10.1.), (ii)
        {{ translate(community.name) }} proporcionará al Cliente acceso a los Datos, de su propiedad, así como la
        posibilidadde solicitar la exportación de los mismos, durante un periodo de treinta (30) días y de
        conformidad conlos precios de {{ translate(community.name) }} vigentes en ese momento para los Servicios
        aplicables,
        en caso de ser ellos necesarios, (iii) tras el mismo período de treinta (30) días una vez finalizados los
        servicios, {{ translate(community.name) }} eliminará los Datos en los términos indicados en la Política de
        Privacidad, (iv) cada una de las partes realizará de inmediato todos los esfuerzos razonables para
        devolver o destruir cualquier otra Información confidencial de la otra parte, si así se solicita, y (v) no
        eximirá al Cliente del pago de los Importes debidos y no abonados a {{ translate(community.name) }} hasta el fin
        del
        Período de Licencia o de cualquiera de sus prórrogas
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Asimismo, {{ translate(community.name) }} puede cancelar una cuenta gratuita en cualquier momento sin
        notificación
        previa a su entera discreción. El Cliente acepta que {{ translate(community.name) }} no será responsable frente
        al
        Cliente ni frente a terceros por motivo de dicha cancelación. El Cliente será el único responsable de
        exportar los Datos de la cuenta gratuita antes de la finalización del acceso a la misma por parte del
        Cliente por cualquier motivo. A pesar de lo anterior, en el supuesto en que sea {{ translate(community.name) }}
        quién
        finalice la cuenta gratuita a su entera discreción, le dará oportunidad al Cliente para recuperar
        previamente susDatos
      </b-card-text>
      <h2 data-v-35bd2db0="">
        DECLARACIONES, GARANTÍAS Y LIMITACIÓN DE RESPONSABILIDAD
      </h2>
      <h3 data-v-35bd2db0="">
        Declaraciones y garantías
      </h3>
      <b-card-text data-v-35bd2db0="">
        Cada parte manifiesta que dispone de la capacidad jurídica necesaria para suscribir las presentes
        Condiciones. Cada parte garantiza que cumplirá con todas las leyes y normativas aplicables a la prestación
        o al uso de los Servicios,según corresponda. El Cliente garantiza que cumplirá con todaslas leyes,
        regulaciones y ordenanzas aplicables o relacionadas con el uso que haga de los Servicios y que toda la
        información facilitada a {{ translate(community.name) }} es veraz y cierta.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Garantíaslimitadas
      </h3>
      <b-card-text data-v-35bd2db0="">
        En la medida en que la ley lo permita, a menos de que quede expresado de otro modo en las presentes
        Condiciones, ninguna de las partes ofrece ninguna otra garantía de ningún tipo, implícita ni explícita,
        obligatoria ni de otra clase incluidas, sin limitarse a ello, las garantías de comerciabilidad, adecuación
        para un fin particular y no infracción. {{ translate(community.name) }} no se hace responsable del contenido ni
        de la
        información a la que se pueda acceder a través de los Servicios.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Cliente reconoce y acepta que cada uno de los Servicios puede presentar errores, defectos y otros
        problemas que podrían provocar un fallo del sistema. En consecuencia, los Servicios, incluido todo el
        contenido,software (incluida cualquier actualización o modificación del mismo), funciones, materiales e
        información puestos a disposición o a los que se acceda a través de los Servicios, así como toda
        documentación que los acompañe, se proporcionan “tal cual”, por lo que todo uso que se haga de estos será
        responsabilidad única del Cliente. {{ translate(community.name) }} y sus proveedores de aplicaciones integradas
        con
        la Solución no ofrecen garantía de ningún tipo, implícita ni explícita, obligatoria ni de otra clase
        incluidas, sin limitación, las garantías de comerciabilidad, adecuación para un fin particular y de no
        infracción. {{ translate(community.name) }} no asume ningún tipo de responsabilidad derivada del uso correcto de
        los
        Servicios. {{ translate(community.name) }} y sus proveedores de aplicaciones integradas con la Solución no
        emiten
        declaraciones relacionadas con ningún contenido ni información accesible a través de los Servicios.
        {{ translate(community.name) }} no emite ninguna declaración de que {{ translate(community.name) }}, o ningún
        tercero, publicará
        actualizaciones o mejoras de los Servicios. {{ translate(community.name) }} no garantiza que las funciones
        incluidas
        en los Servicios no se verán interrumpidas ni que carezcan de errores.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Limitación de responsabilidad
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} NI SUS PROVEEDORES DE APLICACIONES INTEGRADAS CON LA SOLUCIÓNNO SE
        RESPONSABILIZARÁN
        DE NINGÚN DAÑO DIRECTO O INDIRECTO INCLUIDOS, SIN LIMITACIONES, LOS DAÑOS CAUSADOS POR LA INTERRUPCIÓN DEL
        USO O POR LA PÉRDIDA DE DATOS O LA PRESENCIA DE DATOS IMPRECISOS O DAÑADOS, PÉRDIDA DE BENEFICIOS O GASTOS
        DEL ABASTECIMIENTO DE BIENESO SERVICIOS SUSTITUTIVOS, SEA CUAL SEA SU CAUSA, INCLUIDOS, SIN LIMITACIÓN, EL
        USO, MAL USO, INCAPACIDAD DE USO O USO INTERRUMPIDO, NI BAJO NINGUNA TEORÍA DE RESPONSABILIDAD INCLUIDOS,
        SIN LIMITACIÓN, CONTRATOS O AGRAVIOS E INDEPENDIENTEMENTE DE SI {{ translate(community.name) }} TENÍA O DEBERÍA
        HABER
        TENIDO CONOCIMIENTO O HABER SIDO ADVERTIDO DE LAPOSIBILIDAD DE ESTOS DAÑOS, AL MARGEN DE SI CUALQUIER
        REMEDIO ESPECIFICADO EN LAS PRESENTES CONDICIONES NO CUMPLIERA SU FINALIDAD ESENCIAL; O DE NINGUNA
        RECLAMACIÓN QUE ALEGUE DAÑOS DERIVADOS DE ERRORES, OMISIONES U OTRAS IMPRECISIONES DE LOS SERVICIOS O LAS
        PROPIEDADES DE LOS MISMOS.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Limitación de las responsabilidades indirectas
      </h3>
      <b-card-text data-v-35bd2db0="">
        NINGUNA DE LAS PARTES SERÁ RESPONSABLE POR DAÑO EMERGENTE, LUCRO CESANTE NI DAÑO INDIRECTO.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Limitación del importe por responsabilidad
      </h3>
      <b-card-text data-v-35bd2db0="">
        Las Partes acuerdan limitar la responsabilidad derivada de cualquier daño o incumplimiento imputable a
        {{ translate(community.name) }} al importe efectivamente pagado por el Cliente según lo estipulado en estas
        Condiciones, durante los seis (6) meses anteriores al evento que ha dado lugar a dicha responsabilidad.
        {{ translate(community.name) }} no otorga garantía alguna por el uso de Servicios que no estén sujetos a
        contraprestación o durante el periodo de prueba.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        {{ translate(community.name) }} COMO ENCARGADO DEL TRATAMIENTO DE LOS “DATOS”
      </h2>
      <b-card-text data-v-35bd2db0="">
        La captación y tratamiento de datos personales realizados a través de nuestro Sitio Web y a través de la
        prestación de los Servicios aplicables a las presentes Condiciones se rige por lo dispuesto en nuestra
        Política de Privacidad y Política de Cookies que quedan incorporadas a los presentesTérminos y
        Condiciones.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} sólo es responsable en cuanto al tratamiento de los datos de contacto y
        facturación
        delos Clientes.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Por lo que se refiere a los Datos, a los oportunos efectos, detallamos a continuación la información
        básica sobre protección de datos:
      </b-card-text>
      <div data-v-35bd2db0="" class="table">
        <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
          <div data-v-35bd2db0="" class="col-lg-3">
            Encargado del Tratamiento
          </div>
          <div data-v-35bd2db0="" class="col-lg-9">
            Empresa {{ translate(community.name) }} Technologies SL
          </div>
        </div>
        <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
          <div data-v-35bd2db0="" class="col-lg-3">
            Finalidad del Tratamiento
          </div>
          <div data-v-35bd2db0="" class="col-lg-9">
            Gestionar la prestación de servicios
          </div>
        </div>
        <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
          <div data-v-35bd2db0="" class="col-lg-3">
            Base jurídica
          </div>
          <div data-v-35bd2db0="" class="col-lg-9">
            La Solución de {{ translate(community.name) }} permite a los Clientes procesar los “Datos”, por lo quela
            base
            jurídica para que la empresa contratante trate dichos datos como encargado del tratamiento es la
            necesidad de prestar los Servicios conforme a las presentes Condiciones Generales de Uso y
            Contratación (art. 6.1 b) GDPR).
          </div>
        </div>
        <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
          <div data-v-35bd2db0="" class="col-lg-3">
            Destinatarios
          </div>
          <div data-v-35bd2db0="" class="col-lg-9">
            Se podrán ceder datos a otras empresas del grupo {{ translate(community.name) }}, en la medida necesaria
            para
            prestar los Servicios y para el cumplimiento de obligacioneslegales. <br data-v-35bd2db0="">
            Se pueden usar encargados de tratamiento fuera de la UE, en países con las garantías adecuadas o
            cumpliendo con los requerimientos legales en cada momento.
          </div>
        </div>
        <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
          <div data-v-35bd2db0="" class="col-lg-3">
            Derechos
          </div>
          <div data-v-35bd2db0="" class="col-lg-9">
            Acceder, rectificar y suprimir sus datos, solicitar la portabilidad de los mismos, oponerse al
            tratamiento y solicitar la limitación de éste.
          </div>
        </div>
      </div>
      <h2 data-v-35bd2db0="">
        SOPORTE
      </h2>
      <b-card-text data-v-35bd2db0="">
        La Licencia de Usuario incluye un servicio de soporte básico a prestar por {{ translate(community.name) }} al
        Cliente
        conforme al servicio soporte básico que corresponda a la edición o paquete de la Solución que el Cliente
        haya contratado (en adelante el “Servicio de Soporte”).
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        A dichos efectos, el Cliente y el administrador del Cliente podrán ponerse en contacto con losservicios de
        asistencia técnica proporcionados por {{ translate(community.name) }}, mediante los canales habilitados para
        ello
        encada momento, resultando en un caso (“ticket”) que será gestionado por {{ translate(community.name) }} hasta
        su
        cierre.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} atenderá y responderá a las incidencias o consultas enviando mensajes de correo
        electrónico a la dirección de correo electrónico y/o contactando al número de teléfono que el Cliente
        tenga asignados en su Cuenta de Usuario.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Servicio de Soporte es de carácter correctivo, es decir, está orientado a la corrección de incidencias
        en el funcionamiento del producto estándar, es decir, la versión base de la Solución, común a cualquier
        contratación por parte de un cliente, y sobre la cual se efectuarán las personalizaciones contratadas por
        cada cliente (en adelante el “Producto Standard”). El Servicio de Soporte no gestiona incidencias
        producidas en las personalizaciones particulares del cliente realizadas por los equipos de servicios
        profesionales de {{ translate(community.name) }} o sus partners.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} dará asistencia técnica con respecto a las dos últimas versiones aprobadas de la
        Solución.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Además, {{ translate(community.name) }} podrá descatalogar partes o versiones de la Solución previa comunicación
        a
        los clientes con antelación suficiente y razonable para que éstos puedan adaptarse a los cambios. La
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        descatalogación de una parte o versión de la Solución supone que
        {{ translate(community.name) }} no dará soporte ni realizará acciones de mantenimiento evolutivo o correctivo
        sobre
        dicha parte o versiones.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Cliente podrá contratar a {{ translate(community.name) }} servicios de soporte más avanzados, que estarán
        sujetos
        alas presentes Condiciones Generales y a las Condiciones Particulares que les resulten de aplicación.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        SERVICIOS PROFESIONALES
      </h2>
      <b-card-text data-v-35bd2db0="">
        Las capacidades estándar de la Solución pueden ser extendidas para un cliente concreto por consultores
        especializados en la tecnología de la Solución, ya sean consultores de {{ translate(community.name) }} o
        partners
        certificados, dando como resultado un producto extendido que consta del Producto Estándar más sus
        personalizaciones.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Las personalizaciones, por ser partes de la Solución desarrolladas únicamente para un cliente, están
        sujetas a las condiciones de garantía, mantenimiento o soporte estipuladas por el implantador (prestador
        de servicios profesionales) que las haya realizado. El prestador de servicios profesionales podrá ser
        {{ translate(community.name) }} directamente o cualquiera de sus Partners certificados.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Cada implementador de la Solución definirá los términos y condiciones aplicables a la prestación de
        servicios profesionales en un documento específico.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        INDEMNIZACIÓN
      </h2>
      <b-card-text data-v-35bd2db0="">
        El Cliente deberá indemnizar y mantener indemne a {{ translate(community.name) }} y sus sucursales, filiales,
        empresasdel grupo, proveedores, directores, accionistas, empleados, colaboradores y agentes, de cualquier
        reclamación o demanda de un tercero, en relación con (i) los Datos, (ii) cualquier infracción por parte
        del Cliente de los Derechos de propiedad intelectual y/o industrial de un tercero o (iii) un uso de los
        Servicios por parte del Cliente o del Usuario que infrinja lostérminos y condiciones de uso establecidas
        en las presentes Condiciones Generales.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Cliente tendrá que indemnizar a {{ translate(community.name) }} por las reclamaciones derivadas de cualquier
        incumplimiento surgido de la combinación de los Servicios con cualquier otro producto, servicio, hardware
        o proceso empresarial
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        En ningún caso {{ translate(community.name) }} tendrá obligación ni responsabilidad alguna, según se establece
        en
        este apartado, derivadas del (i) uso de los Servicios o elementos de marca de {{ translate(community.name) }} de
        forma modificada o en combinación con otros materiales no facilitados por {{ translate(community.name) }} y (ii)
        contenido, información o datos proporcionados por el Cliente, {{ translate(community.name) }}, Usuarios u otros
        terceros.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        POSIBLES INFRACCIONES
      </h2>
      <h3 data-v-35bd2db0="">
        Reparar,sustituir o modificar
      </h3>
      <b-card-text data-v-35bd2db0="">
        Si {{ translate(community.name) }} tiene sospechas razonables de que los Servicios infringen los Derechos de
        propiedad intelectual y/o industrial de un tercero, {{ translate(community.name) }} (a) obtendrá la autorización
        de
        uso por parte del tercero para el Cliente, a cargo de {{ translate(community.name) }}, para que pueda seguir
        usando
        los Servicios, (b) proporcionará un sustituto funcionalmente equivalente que no los infrinja o (c)
        modificará los Servicios para que la infracción no se vuelva a producir.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Suspensión o rescisión
      </h3>
      <b-card-text data-v-35bd2db0="">
        Si {{ translate(community.name) }} considera que las opciones anteriores no son razonables, puede suspender o
        rescindirel uso de los Servicios afectados. Si {{ translate(community.name) }} rescinde los Servicios afectados,
        deberá proporcionar una devolución prorrateada de los Importes no devengados ya pagados por el Cliente
        aplicables al periodo siguiente a la rescisión de dicho Servicio.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        OTRAS DISPOSICIONES
      </h2>
      <h3 data-v-35bd2db0="">
        Software de terceros
      </h3>
      <b-card-text data-v-35bd2db0="">
        A efectos aclaratorios se hace constar que las presentes Condiciones serán de aplicación al Cliente y/o al
        Usuario por lo que respecta al uso por parte de éstos de cualquier funcionalidad permitida por la Solución
        que sea proporcionada por un tercero proveedor. Es decir, las limitaciones de uso, obligaciones y
        prohibiciones contenidas en las presentes Condiciones con respecto a la Solución, lo son con respecto a
        cualquier software de tercero incluido en ésta.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Modificación
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} se reserva el derecho de cambiar o modificar cualquiera de las presentes
        Condiciones,así como toda política que rija los Servicios, en cualquier momento, mediante la publicación
        de las condiciones en <a data-v-35bd2db0="" href=" http://nectios.com "> http://nectios.com </a>o
        cualquier otra URL que {{ translate(community.name) }} proporcione. El Cliente es responsable de consultar con
        regularidad las posibles actualizaciones de las presentes Condiciones. Todos los cambios o modificaciones
        que se realicen serán vinculantes (i) cuando ambas partes los acepten por escrito, (ii) cuando el Cliente
        acepte online la actualización de los mismos o si (iii) una vez que {{ translate(community.name) }} actualice
        dichos
        términos, el Cliente sigue haciendo un uso continuadode los Servicios.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Comunicaciones
      </h3>
      <b-card-text data-v-35bd2db0="">
        Todas las notificaciones y comunicaciones que procedan, derivadas de la relación contractual objeto de las
        presentes Condiciones se enviarán por escrito, pudiendo enviarse incluso por correo electrónico siempre
        que se pueda dejar constancia de su recepción. A dichos efectos el Cliente podrá enviar sus comunicaciones
        a la dirección de
        <a data-v-35bd2db0="" href="mailto:hello@nectios.com.">hello@nectios.com.</a>
        {{ translate(community.name) }} podrá enviar las comunicaciones a la dirección electrónica que el cliente haya
        notificado en la documentación relacionada con la venta de la Solución. Las Partes podrán varias las
        direcciones facilitadas, comunicándolo a la otra por escrito
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Cesión
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Cliente no podrá ceder ni transferir su posición contractual, ni los derechos y obligaciones asumidos
        en virtud de las presentes Condiciones, sin el consentimiento por escrito de {{ translate(community.name) }}, y
        en
        todocaso se deberá cumplir lo siguiente, que (a) el beneficiario acepte por escrito obligarse a las
        condiciones de las presentes Condiciones y (b) la parte que efectúa la cesión sigue siendo responsable de
        las obligaciones contraídas mediante las presentes Condiciones antes de dicha cesión. Cualquier otro
        intento de cesión se considerará nulo de pleno derecho.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} tendrá derecho a prestar los Servicios directamente o a través de cualquiera de
        las
        compañías que forman parte del grupo {{ translate(community.name) }}, listadas en el Anexo I, listado que está
        actualizado en todo momento en la política de privacidad de {{ translate(community.name) }}, debiendo aceptar el
        Cliente la prestación de dichos Servicios por las filiales como si hubieran sido prestados por
        {{ translate(community.name) }}.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Fuerza mayor
      </h3>
      <b-card-text data-v-35bd2db0="">
        Ninguna de las partes será responsable por el incumplimiento de las obligaciones establecidas en las
        presentes Condiciones, siempre y cuando esté causado por una circunstancia ajena al control razonable de
        la otra parte como, por ejemplo, desastres naturales, actos de guerra o terrorismo, disturbios, conflictos
        laborales, actuaciones del Gobierno y fallos de Internet.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Vigencia de las condiciones
      </h3>
      <b-card-text data-v-35bd2db0="">
        Si alguna disposición de las presentes Condicionesse considera inaplicable, las disposicionesrestantes
        permanecerán en vigor.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Inexistencia de representación
      </h3>
      <b-card-text data-v-35bd2db0="">
        Las partes contratantes son independientes y las presentes Condiciones no constituyen ninguna
        representación, asociación o empresa conjunta.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Empresa contratante, ley y jurisdicción aplicable
      </h3>
      <b-card-text data-v-35bd2db0="">
        En Anexo I se detallan las empresas contratantes por parte del grupo
        {{ translate(community.name) }}, así como la ley y jurisdicción aplicable a las presentes Condiciones en función
        del
        domicilio en el que se encuentre el Cliente.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Enmiendas
      </h3>
      <b-card-text data-v-35bd2db0="">
        Toda enmienda debe hacerse por escrito e indicar expresamente que se trata de una enmienda de las
        presentes Condiciones.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Acuerdo completo
      </h3>
      <b-card-text data-v-35bd2db0="">
        Las presentes Condiciones y todoslos documentos a los que se hace mención o remisión en las mismas
        constituyen la totalidad del acuerdo entre las partes respecto a la materia objeto de las mismas y
        sustituyen a todos los acuerdos previos o actuales sobre dicha materia.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Interpretación de condiciones en conflicto
      </h3>
      <b-card-text data-v-35bd2db0="">
        En caso de existir algún conflicto entre las Condiciones y cualquier versión anterior de las mismas, y
        salvo que se indique de otro modo, prevalecerán las disposiciones de las presentes Condiciones.
      </b-card-text>
    </div>
    <div v-if="currentLocale === 'ca'">
      <h2 data-v-35bd2db0="">
        Informació legal
      </h2>
      <b-card-text data-v-35bd2db0="">
        Les presents Condicions Generals d'Ús i contractació (d'ara endavant, les «Condicions») regulen la
        adquisició i ús dels productes i serveis que {{ translate(community.name) }} (segons aquest terme es defineix
        a continuació) posa a disposició dels seus usuaris i/o clients, ja sigui a través d'accés web, al
        portal d'Internet <a data-v-35bd2db0="" href="https://nectios.com/">https://nectios.com/</a> (a
        endavant, el “Lloc Web” o la “Web” indistintament), a través de dispositius iOS o Android, en línia o
        fora de línia, així com a través de l'aplicació d'escriptori (d'ara endavant, la “Solució”). La
        utilització de la Web i de la Solució li atribueix la condició d'usuari (d'ara endavant, “l'Usuari”) i
        la contractació dels serveis, tal com aquests es defineixen més endavant, li atribueix la condició de
        client (d'ara endavant, el Client) i tots dos impliquen l'acceptació de les presents Condicions. Alguns
        serveis o funcionalitats de la Solució poden estar sotmesos a condicions particulars que, en la seva
        cas, modifiquen o complementen les presents Condicions i que s'entenen acceptades per l'Usuari i/o
        el Client a l'inici de la prestació del servei esmentat o de la utilització de la corresponent
        funcionalitat (d'ara endavant, les Condicions Particulars”).{{ translate(community.name) }} es reserva el dret
        de
        modificar, en qualsevol moment i sense previ avís, el contingut i configuració de la Web i de la
        Solució, així com dels serveis oferts. Els Usuaris i Clients han de llegir atentament les presents
        Condicions. En fer clic indicant la seva acceptació, en acceptar una Ordre de Compra dels Serveis (tal i
        com aquests termes es defineixen més endavant) o en utilitzar serveis gratuïts, el Client i/o l'Usuari
        accepten aquestes Condicions que regeixen l'ús dels Serveis. Així mateix, el registre o ús de la
        Solució implica la íntegra acceptació de les presents Condicions, així com de les polítiques de
        privadesa de {{ translate(community.name) }} incloses a
        <a data-v-35bd2db0=""
          href="http://nectios.com/my/legal-center/?legal=privacy_policy">http://nectios.com/my/legal-center/</a>
        (d'ara endavant, la “Política de Privadesa”). Finalment, en cas que un Client contracti els
        Serveis a través d'un distribuïdor autoritzat de {{ translate(community.name) }}, li seran d'aplicació les
        presents Condicions Generals, a excepció dels aspectes relatius a pagament, facturació,
        notificacions i, si escau, serveis d'assistència tècnica que, en virtut de l'acord comercial
        existent entre el distribuïdor i el Client, no li són aplicables.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        Serveis
      </h2>
      <h3 data-v-35bd2db0="">
        Descripció dels Serveis
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} posa a disposició del Client, a través de la Solució, un conjunt de serveis de
        gestió d'equips comercials descrit detalladament a la direcció <a data-v-35bd2db0=""
          href="http://nectios.com">http://nectios.com</a>
        o en una altra URL similar que pogués
        proporcionar {{ translate(community.name) }} (d'ara endavant, els “Serveis”). Els serveis s'ofereixen en línia a
        a través de laplicació Web, a través de dispositius mòbils, tant fora de línia com en línia. La
        Solució permetrà lús duna interfície basada en diferents aplicacions tablet, web i mòbil, així com
        xifrat, transmissió, accés i emmagatzematge de dades. Els serveis s'ofereixen a diverses
        edicions i paquets que poden diferir notablement a les seves funcionalitats. Consulteu el nostre lloc web
        per conèixer les diferències entre les diferents edicions. No es pot accedir a ni utilitzar els
        Serveis per tal de controlar la seva disponibilitat, rendiment o funcionalitat, ni per a cap altre
        propòsit d'avaluació comparativa o fi competitiu
      </b-card-text>
      <h3 data-v-35bd2db0="">
        2.2 Dades en la prestació dels serveis
      </h3>
      <b-card-text data-v-35bd2db0="">
        La Solució permet al Client processar la informació basada en (i) els horaris d'activitat comercial
        de l'Usuari, (ii) la seva ubicació geogràfica, (iii) les comunicacions rebudes o enviades per un Usuari,
        (iv) data, durada i interlocutor de les trucades telefòniques rebudes o realitzades, (v) les ordres
        de veu enviats a la Solució, (vi) fotografies facilitades per l'Usuari, (vii) detalls de les
        vídeotrucades mantingudes a través de solucions integrades a la plataforma, com ara l'hora,
        durada i contingut de les trucades esmentades, que podrà incloure la gravació íntegra de les mateixes,
        independentment que s'hagin mantingut amb l'opció de vídeo (càmera) activat o no; així com una altra
        informació relacionada amb la seva activitat comercial i que trobareu actualitzada en tot moment a la
        Política de Privadesa de {{ translate(community.name) }} al link
        <a data-v-35bd2db0="" href="https://nectios.com/ca/info/privacidad/">https://nectios.com/es/info/privacidad/</a>
        (d'ara endavant les “Dades dels Usuaris”). El Client, i si escau l'Usuari, podrà donar-se de baixa de
        aquestes funcionalitats en qualsevol moment mitjançant ledició de la configuració a nivell de dispositiu
        o posant-se en contacte amb {{ translate(community.name) }}. Per contra, el Client podrà activar-ne de noves
        funcionalitats posant-se en contacte amb {{ translate(community.name) }}, sota un nou pressupost, devent
        informar-ne l'Usuari. Així mateix, i per a l'efectiva prestació dels serveis i funcionament de
        la Solució, el Client i/o cada Usuari podran enviar a la Solució aquelles dades, informacions o
        materials que considerin oportú, incloent dades, informacions o materials de tercers, especialment
        els clients o clients potencials del Client (aquests darrers, d'ara endavant, les “Dades de Tercers”). Els
        Dades dels Usuaris i les Dades de Tercers seran denominades conjuntament com les “Dades”. A efectes
        aclaridors, les Dades inclouran qualsevol dada bolcada a la Solució (exceptuant les dades de contacte
        i facturació del propi Client). En qualsevol cas, les dades enviades a l'aplicació per part del
        Client i/o l'Usuari, hauran de complir amb les presents Condicions i Política de Privadesa.
        {{ translate(community.name) }} només compartirà les Dades en els termes indicats a la Política de Privadesa.
        Així
        mateix, el Client declara i garanteix que té la corresponent autorització per gestionar les Dades
        de Tercers mitjançant lús de la Solució. Mitjançant el marcatge de la casella habilitada a tals
        efectes, el Client accepta rebre periòdicament per part de {{ translate(community.name) }}, comunicacions de
        màrqueting relatives als productes i serveis de {{ translate(community.name) }}, així com newsletters de
        {{ translate(community.name) }} amb notícies i novetats sobre vendes, tecnologia i el sector del Client. El
        Client
        podrà notificar a {{ translate(community.name) }}, en qualsevol moment i per correu electrònic a l'adreça
        <a data-v-35bd2db0="" :href="email">newsletter{{ translate(community.name) }}.tech</a> , el vostre desig de no
        rebre'n una o
        ambdues comunicacions.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Modificacions
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} podrà realitzar els canvis, modificacions i ajustaments que consideri raonables
        en els
        Serveis quan ho consideri oportú. En cas de realitzar un canvi substancial als Serveis,
        {{ translate(community.name) }} ho haurà de comunicar prèviament al Client.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        OBLIGACIONS DEL CLIENT
      </h2>
      <h3 data-v-35bd2db0="">
         Compliment de les Condicions
      </h3>
      <b-card-text data-v-35bd2db0="">
        Per aquest motiu, el Client i l'Usuari s'obliguen a utilitzar els Serveis d'acord amb els termes
        establerts en aquestes Condicions. {{ translate(community.name) }} pot oferir, segons consideri oportú,
        aplicacions, característiques o funcions addicionals a través dels serveis, l'ús dels quals pot estar
        supeditat a Condicions Particulars que hauran de ser acceptades pel Client i, si escau, pel
        Usuari.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Condicions dús i límits
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Client i l'Usuari compliran amb les condicions d'ús i els límits relatius a l'ús dels Serveis
        que estableixi {{ translate(community.name) }} a cada moment, inclosos, sense limitacions: (i) requisits tècnics
        i d'implementació de la solució; (ii) directrius de tractament de les marques titularitat de
        {{ translate(community.name) }} pels Serveis; (iii) termes d'ús i política de privadesa del Lloc Web i de
        la Solució i (iv) requisits de {{ translate(community.name) }} per a protecció de dades i seguretat.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Ús permès i restriccions d'ús
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Client i l'Usuari accepten utilitzar els Serveis exclusivament de conformitat amb les presents
        Condicions i la legislació aplicable. Així mateix, el Client i l'Usuari es comprometen a no involucrar-s'hi
        en cap activitat que interfereixi amb els serveis o els servidors o xarxes connectats als serveis o
        que els pugui interrompre. A més de regir-se per aquestes Condicions, lús de determinats serveis
        específics de {{ translate(community.name) }} per part del Client estarà regit per les Condicions
        Particulars
        que es presenten al Client quan aquest es registra o accedeix als Serveis en qüestió, incorporant-s'hi
        específicament aquestes en el moment d'acceptació per part del Client.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        A menys que {{ translate(community.name) }} ho acordi específicament per escrit, ni el Client ni el
        Usuari
        podran dur a terme cap de les accions que es descriuen a continuació, devent el Client
        assegurar-se que l'Usuari compleixi amb allò especificat aquí. Així mateix, tant el Client com els Usuaris
        hauran de realitzar tots els esforços raonables per assegurar-se que cap tercer no els dugui a terme:
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        (i) utilitzar la Solució de manera que pugui danyar, deshabilitar, sobrecarregar o deteriorar-la, ni
        utilitzar-la de manera que pugui interferir en l'ús i el gaudi de la Solució per part de tercers; (ii)
        eliminar, amagar o alterar avisos de drets d'autor, marques o altres avisos de drets de propietat,
        que contingui la Solució;(iii) enviar correus escombraries o missatges duplicats o no desitjats que infringeixin la
        normativa aplicable; (iv) enviar o emmagatzemar material il·lícit, obscè, amenaçant, difamatori, il·legal o
        greuge, inclòs material perjudicial per als nens o que infringeixi els drets de privadesa de
        tercers; (v) enviar o emmagatzemar material que contingui programari amb virus, cucs, troians o codis,
        arxius, seqüències d'ordres, agents o programes informàtics nocius; (vi) interferir o afectar la
        integritat o rendiment dels serveis o la solució, o les dades que conté; o (vii) intentar
        obtenir accés no autoritzat a la Solució o als sistemes o xarxes relacionats.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        3.4 Administració dels serveis per part del Client
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Client rebrà un nom i una contrasenya que haurà d'utilitzar als efectes d'administració de les
        comptes de Client. El Client té la responsabilitat de mantenir la confidencialitat de la dita
        contrasenya i compte, per a això designarà els empleats que disposaran d'autorització per accedir a
        el compte i limitarà l'abast de la dita autorització al compliment de les tasques assignades i per a totes
        les activitats que es facin en el marc del compte del Client. El Client accepta notificar de forma
        immediata a {{ translate(community.name) }} sobre qualsevol ús o accés no autoritzat als Serveis, la Solució,
        a la contrasenya o compte del Client o qualsevol altre tipus de vulneració de la seguretat.
        {{ translate(community.name) }} no podrà responsabilitzar-se ni es responsabilitzarà de cap pèrdua o dany
        derivat
        de l'incompliment de les obligacions de seguretat per part del client. El Client reconeix accepta que
        {{ translate(community.name) }} no es responsabilitzarà de cap manera ni sota cap circumstància de cap acció
        o omissió per part del Client o de qualsevol Usuari, inclosos els danys de qualsevol tipus resultants
        d'aquestes accions o omissions.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Responsabilitat del Client i consentiment de l'Usuari
      </h3>
      <b-card-text data-v-35bd2db0="">
        L'administrador del Client pot tenir la capacitat d'accedir, supervisar, utilitzar o divulgar els
        Dades dels Usuaris i les Dades de Tercers dins dels comptes dels Usuaris vinculats al Client,
        configurant-se el Client com a responsable del tractament daquestes dades. És responsabilitat exclusiva
        del Client complir totes les obligacions que es derivin de la seva condició de responsable del tractament,
        amb sencera indemnitat per a {{ translate(community.name) }}.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Així mateix, el Client i l'Usuari són coneixedors que, en cas que el Client hagi donat d'alta
        aquesta funcionalitat, qualsevol Usuari vinculat amb el Client pot accedir i supervisar qualsevol dada
        dins dels comptes de qualsevol altre Usuari vinculat amb el mateix Client, ja que qualsevol Usuari
        vinculat amb el Client pot tenir visibilitat dels comptes dels altres Usuaris vinculats amb el
        mateix Client. El Client garanteix que compta amb el consentiment exprés dels Usuaris per permetre
        aquesta funcionalitat. El Client, i si escau l'Usuari, podrà donar-se de baixa d'aquestes funcionalitats a
        qualsevol moment mitjançant l'edició de la configuració a nivell de dispositiu o posant-se en contacte
        amb {{ translate(community.name) }}.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Ús no autoritzat
      </h3>
      El Client durà a terme els seus millors esforços per impedir lús no autoritzat dels Serveis i per
      cancel·lar usos no autoritzats. El Client informarà immediatament a {{ translate(community.name) }} de qualsevol
      ús o
      accés no autoritzat al Servei del qual en tingui coneixement.
      <b-card-text data-v-35bd2db0="" />
      <h3 data-v-35bd2db0="">
        Condicions aplicables a l'Usuari
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Client reconeix que serà responsable de tota activitat que es produeixi al vostre compte de Client i que
        els seus Usuaris estan subjectes a les presents Condicions pel que fa a cadascun dels components
        dels Serveis, així com a les Condicions Particulars que puguin ser aplicables a determinats
        Serveis.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        En cas que el Client tingui notícia d'alguna infracció de les presents Condicions o de les
        Condicions particulars en aquells extrems aplicables als usuaris, o d'altres condicions dels usuaris
        Serveis o polítiques aplicables per part d'un Usuari, el Client accepta (i) notificar-ho a
        {{ translate(community.name) }} tan aviat com sigui possible; i (ii) suspendre o cancel·lar de manera immediata
        la
        compte d'aquest Usuari a menys que {{ translate(community.name) }} consenti el contrari per escrit (fins i tot a
        través del correu electrònic). A petició de {{ translate(community.name) }}, el Client suspendrà o cancel·larà
        de
        forma immediata qualsevol Compte de l'Usuari o accés d'administrador al Servei com a resposta a un
        infracció dels termes dús o política aplicables per part dun Usuari. {{ translate(community.name) }}
        es reserva el dret, a la vostra discreció, de suspendre o cancel·lar el Compte d'Usuari de qualsevol Usuari.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Informació i dades del compte
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} no és propietari de les dades, informacions o materials que enviï el Client i/o
        l'Usuari als Serveis i la Solució durant el seu ús (les “Dades dels Usuaris i les Dades de
        Tercers”). El Client, i no {{ translate(community.name) }}, serà l'únic responsable de l'exactitud, la qualitat,
        integritat, legalitat, fiabilitat, idoneïtat i drets de propietat intel·lectual i/o industrial en l'ús
        de les Dades i {{ translate(community.name) }} no serà responsable de l'eliminació, correcció, destrucció, dany,
        pèrdua o error sorgit durant l'emmagatzematge de les dades, i en general, de qualsevol dada pujada a
        la Solució.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Sempre que el Client ho sol·licite en el moment de la resolució de la contractació dels Serveis,
        {{ translate(community.name) }} posarà a disposició del Client un arxiu amb les Dades (titularitat del Client)
        durant els trenta (30) dies posteriors a l'extinció dels serveis
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Interacció amb tercers
      </h3>

      <b-card-text data-v-35bd2db0="">
        Durant l'ús dels serveis, el client pot compartir correspondència, adquirir béns o serveis o
        participar en promocions d'anunciants o patrocinadors que mostrin els seus béns i/o serveis a través
        dels serveis.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Qualsevol d'aquestes activitats i qualsevol condició, manifestació o garantia associada a aquestes
        activitats és exclusiva entre el Client i aquests tercers. {{ translate(community.name) }} i els seus
        distribuïdors no
        tenen cap responsabilitat o obligació derivada d'aquesta correspondència, adquisició o promoció
        entre el Client i aquests tercers.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} no dóna suport a cap lloc d'Internet al qual s'accedeixi a través dels serveis.
        {{ translate(community.name) }} ofereix aquests enllaços al Client per a la seva comoditat i en cap cas
        {{ translate(community.name) }} o els seus distribuïdors seran responsables del contingut, els productes o
        altres
        materials disponibles en aquests enllaços.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} ofereix els Serveis al Client de conformitat amb les presents Condicions i,
        quan correspongui, d'acord amb les condicions particulars. Tot i això, el Client admet que altres
        proveïdors de programari, maquinari o serveis addicionals poden requerir l'acceptació per part del
        Client d'una altra llicència diferent o addicional o altres condicions abans de poder utilitzar o accedir a
        aquest programari, maquinari o serveis.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        FACTURACIÓ I PAGAMENT
      </h2>
      <h3 data-v-35bd2db0="">
        Períodes de prova gratuïta
      </h3>
      <b-card-text data-v-35bd2db0="">
        En aquelles contractacions a què s'apliquin períodes inicials de prova gratuïts d'acord amb allò
        disposat a l'Ordre de Compra (segons aquest terme es defineix en la condició 4.2 següent) no es
        realitzarà cap càrrec mentre duri aquest període. Si el Client decideix cancel·lar els Serveis abans de
        finalitzar el període de prova, no se li farà cap càrrec.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Forma de pagament
      </h3>
      <b-card-text data-v-35bd2db0="">
        Tots els pagaments es realitzaran en la forma indicada a l'ordre de compra de serveis acceptada pel
        Client (d'ara endavant, l'Ordre de Compra). El Client realitzarà el pagament dels imports indicats a la
        Ordre de Compra (d'ara endavant, els “Imports”).
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Els Imports, una vegada facturats, s'han d'abonar al principi del període en què el Client rep els
        Serveis i amb la periodicitat acordada a l'Ordre de Compra. No es podran cancel·lar les obligacions de
        pagament ni reemborsar les quantitats ja pagades. El Client té l'obligació de pagar totes les llicències
        d'Usuari sol·licitades per a tot el període de llicència establert a l'Ordre de Compra (el “Període de
        Llicència”), tant si estan en ús com si no.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Factures
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Client s'obliga a oferir informació completa i precisa de facturació i de contacte a
        {{ translate(community.name) }}. Aquesta informació inclou el nom de la companyia, número de compte corrent,
        targeta de crèdit (si és el cas) o una altra forma de pagament aplicable, adreça postal, adreça de correu
        electrònic, nom i número de telèfon d'un contacte autoritzat per a la facturació, el número de
        identificació de l'impost sobre el valor afegit intracomunitari (si és el cas) i dades de l'administrador
        del Client.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Client es compromet a actualitzar aquesta informació en un termini de deu (10) dies després de qualsevol
        modificació. Si la informació de contacte que ha ofert esfalsa, fraudulenta o no està actualitzada,
        {{ translate(community.name) }} es reserva el dret de cancel·lar l'accés al Servei, així com d'emprendre
        qualsevol altra mesura legal que estimi convenient
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Si el Client considerés que la factura no és correcta, haurà de posar-se en contacte amb
        {{ translate(community.name) }}o el seu distribuïdor per escrit, en un període màxim de set (7) dies a partir de
        la
        data d'aquesta factura, per revisar conjuntament aquesta factura i, si escau, poder-ne rebre una
        rectificació
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Modificació del nombre de llicències
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Client podrà sol·licitar llicències addicionals en qualsevol moment, que estaran subjectes a les presents
        Condicions i, si escau, a les Condicions Particulars.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Així mateix, el Client podrà reduir el nombre de llicències per a Usuaris mitjançant la tramesa d'un correu
        electrònic a {{ translate(community.name) }} amb una antelació mínima de trenta (30) dies hàbils abans de la
        data
        del següent Període de Llicència.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Modificació del pla de serveis
      </h3>
      <b-card-text data-v-35bd2db0="">
        En cas que el Client sol·liciti una millora (o upgrade) del seu actual pla de serveis, les condicions
        específiques aplicables com a conseqüència d'aquesta millora seran les establertes a l'Ordre de Compra i
        serà prorratejada proporcionalment pel període restant del període de llicència.
      </b-card-text>

      <b-card-text data-v-35bd2db0="">
        Per contra, el Client no podrà sol·licitar una reducció (o downgrade) del pla de serveis
        durant el Període de Llicència, sent únicament aplicable una vegada finalitzat el dit Període de Llicència.
        La sol·licitud d'una reducció també s'ha de fer mitjançant la remissió d'un correu electrònic a
        {{ translate(community.name) }} amb una antelació mínima de trenta (30) dies hàbils abans de la finalització del
        Període de llicència.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Tarifes addicionals
      </h3>
      <h4 data-v-35bd2db0="">
        Tarifa addicional d'emmagatzematge de dades
      </h4>
      <b-card-text data-v-35bd2db0="">
        L'espai d'emmagatzematge de dades que {{ translate(community.name) }} us ofereix de forma gratuïta al Client és
        de1GB en base de dades per al conjunt de Llicències d'Usuari que el Client tingui contractades a
        qualsevol edició o paquet de {{ translate(community.name) }} i 5MB addicionals per cada Llicència d'Usuari de
        qualsevol edició o paquet de {{ translate(community.name) }}.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Si la quantitat d'emmagatzematge de disc requerida pel Client supera els límits establerts al
        paràgraf anterior, {{ translate(community.name) }} es reserva el dret de cobrar al Client la tarifa de
        emmagatzematge vigent en aquell moment. {{ translate(community.name) }} intentarà comunicar al Client, en la
        mesura de
        el possible, quan l'emmagatzematge mitjà utilitzat per llicència arribi al noranta per cent (90%)
        aproximadament del límit indicat. No obstant això, si {{ translate(community.name) }} no us notifica aquesta
        situació,
        això no eximeix el Client de la seva responsabilitat i obligació de pagament davant dels càrrecs
        d'emmagatzematge de
        dades addicionals
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} es reserva el dret d'establir o modificar en qualsevol moment, procedir
        habitual i els límits en relació amb lemmagatzematge de dades. {{ translate(community.name) }} publicarà al
        vostre
        pàgina web la seva política demmagatzematge de dades i els límits vigents en cada moment.
      </b-card-text>
      <h4 data-v-35bd2db0="">
        Tarifa addicional d'emmagatzematge de fitxers
      </h4>
      <b-card-text data-v-35bd2db0="">
        L'espai d'emmagatzematge de fitxers que {{ translate(community.name) }} us ofereix de forma gratuïta al Client
        és de 2,5GB per al conjunt de Llicències d'Usuari que el Client tingui contractades a qualsevol edició
        paquet de {{ translate(community.name) }} i 250MB addicionals per cada Llicència d'Usuari de qualsevol edició o
        paquet de {{ translate(community.name) }}.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Si la quantitat d'emmagatzematge d'arxius requerida pel client supera els límits establerts a
        el paràgraf anterior, {{ translate(community.name) }} es reserva el dret de cobrar al Client la tarifa de
        emmagatzematge vigent en aquell moment. {{ translate(community.name) }} intentarà comunicar al Client, en la
        mesura de
        el possible, quan l'emmagatzematge mitjà utilitzat per llicència arribi al noranta per cent (90%)
        aproximadament del límit indicat. No obstant això, si {{ translate(community.name) }} no us notifica aquesta
        situació,
        això no eximeix el Client de la seva responsabilitat i obligació de pagament davant dels càrrecs
        d'emmagatzematge de
        fitxers addicionals.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} es reserva el dret d'establir o modificar en qualsevol moment, procedir
        habitual i els límits en relació amb lemmagatzematge de fitxers. {{ translate(community.name) }} publicarà al
        vostre
        pàgina web la seva política demmagatzematge darxius i els límits vigents en cada moment.
      </b-card-text>
      <h4 data-v-35bd2db0="">
        Tarifa addicional de serveis de geolocalització
      </h4>
      <b-card-text data-v-35bd2db0="">
        El volum de peticions de geolocalització que {{ translate(community.name) }} ofereix de forma gratuïta al Client
        és de 20.000 al mes pel conjunt de Llicències d'Usuari que el Client tingui contractades a qualsevol
        edició o paquet de {{ translate(community.name) }}.
      </b-card-text>

      <b-card-text data-v-35bd2db0="">
        Si el volum de peticions de geolocalització requerit pel Client supera els límits establerts
        al paràgraf anterior, {{ translate(community.name) }} es reserva el dret de cobrar al Client la tarifa de
        geolocalització vigent en aquell moment. {{ translate(community.name) }} intentarà comunicar al Client, a la
        mesura del possible, quan el volum de peticions de geolocalització utilitzat pel Client arribi
        el noranta per cent (90%) aproximadament del límit indicat. No obstant això, si {{ translate(community.name) }}
        no
        li notifica aquesta situació, això no eximeix al Client de la seva responsabilitat i obligació de pagament
        davant dels
        càrrecs de geolocalització addicionals
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} es reserva el dret d'establir o modificar en qualsevol moment, procedir
        habitual i els límits en relació amb les peticions de geolocalització. {{ translate(community.name) }} publicarà
        a la seva pàgina web la seva política de peticions de geolocalització i els límits vigents a cada moment.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Impagament
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} es reserva el dret a suspendre o extingir les presents Condicions i, en la seva
        cas,les Condicions Particulars que siguin aplicables i, per tant, l'accés als
        Serveis per part del Client si incorre en retards als pagaments per un període superior a deu (10)
        dies des del venciment.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Els comptes amb retards als pagaments estan subjectes a un interès mensual de l'1,5% aplicable sobre el saldo
        pendent, a més de totes les despeses necessàries per al cobrament. Aquest interès no podrà ser fraccionat i un
        només dia de retard implicarà laplicació de la totalitat dels interessos.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Si el Client o {{ translate(community.name) }} finalitzen l'accés al Servei, el Client estarà obligat a abonar
        el saldo pendent del vostre compte.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} es reserva el dret a imposar una tarifa de reconnexió en cas que el Client
        hagi suspès i posteriorment hagi sol·licitat l'accés al Servei.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        IMPOSTOS
      </h2>
      <b-card-text data-v-35bd2db0="">
        Els imports establerts pels Serveis no inclouen impostos, taxes o altres recàrrecs similars de
        cap naturalesa, inclosos sense limitar-s'hi, impostos sobre el valor afegit, de vendes, d'ús o
        retencions, establerts per la legislació aplicable (de manera conjunta, “Impostos”). El Client serà
        responsable del pagament dels Impostos i no podrà realitzar pagaments a {{ translate(community.name) }} pels
        Serveis
        amb deduccions d'impostos incloses.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        SUSPENSIÓ I INTERRUPCIÓ DELS SERVICIS
      </h2>
      <h3 data-v-35bd2db0="">
        De Comptes d'Usuari per part de {{ translate(community.name) }}
      </h3>
      <b-card-text data-v-35bd2db0="">
        En cas d'incompliment de les presents Condicions pel Client, oa l'esdeveniment en què, previ
        requeriment per part de {{ translate(community.name) }}, el Client no hagués adoptat les mesures necessàries
        per al seu compliment en un termini de deu (10) dies, {{ translate(community.name) }} es reserva el dret a
        suspendre
        els Serveis, sense perjudici del que estableix l'apartat 12 de les presents Condicions.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Addicionalment, si {{ translate(community.name) }} té coneixement d'algun incompliment de les presents
        Condicions per part de l'Usuari, podrà sol·licitar al Client la suspensió del Compte d'Usuari a
        qüestió. En cas que el Client no satisfaci aquesta sol·licitud de {{ translate(community.name) }}, serà
        {{ translate(community.name) }}
        qui podrà dur a terme la suspensió del compte d'usuari. La durada de qualsevol suspensió
        prolongarà fins que l'Usuari en qüestió hagi solucionat l'incompliment causant de la mateixa.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Disponibilitat i Accés als Serveis.
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} proporcionarà disponibilitat i accés continu als Serveis, llevat dels
        esdeveniments
        en aquells que, per circumstàncies alienes al seu control, s'hagin de fer suspensions o interrupcions dels
        mateixos, que seran degudament informades al Client. Aquestes interrupcions es poden deure a tercers de
        els que depèn la prestació dels serveis, com ara els prestadors de serveis de hosting i de
        signatura digital, entre altres proveïdors de serveis externs. En aquests casos {{
          translate(community.name)
        }}no
        incorrerà en cap responsabilitat com a conseqüència de la suspensió o interrupció dels serveis.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} realitza els seus millors esforços per dur a terme les tasques de manteniment i
        milloren la Solució de manera que no suposi la interrupció dels Serveis. No obstant això, per al cas
        perquè per dur a terme aquestes tasques sigui necessària la interrupció temporal dels serveis,
        {{ translate(community.name) }} notificarà amb antelació als Clients i als Usuaris de la data prevista per a
        realitzar aquestes tasques i la durada estimada de les mateixes. {{ translate(community.name) }} no incorrerà a
        cap responsabilitat com a conseqüència de la suspensió o interrupció dels serveis per a la
        realització de tasques de manteniment i millora a la Solució sempre que hagi informat opoportunament als Clients
        i els Usuaris conforme al que disposa aquest apartat.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        6.3 Per incidències de seguretat d'urgència
      </h3>
      <b-card-text data-v-35bd2db0="">
        Si hi ha una incidència de seguretat d'urgència, {{ translate(community.name) }} podrà procedir a la suspensió
        automàtica de lús del compte dUsuari. La suspensió tindrà la durada i l'abast mínims
        necessaris per evitar o resoldre la incidència de seguretat durgència. Si, per qualsevol motiu,
        {{ translate(community.name) }} suspèn un compte d'Usuari sense avisar prèviament el Client,
        {{ translate(community.name) }} indicarà el motiu de la suspensió al Client tan aviat com sigui raonablement
        possible, si així ho requereix aquest darrer.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        DRETS DE PROPIETAT INTEL·LECTUAL I/O INDUSTRIAL
      </h2>
      <b-card-text data-v-35bd2db0="">
        A excepció del que estableix expressament el present document, aquestes Condicions no
        garanteixen a cap de les parts cap dret, implícit o no, sobre el contingut o sobre qualsevol
        dret de propietat intel·lectual i/o industrial de l'altra part. En aquest sentit,
        {{ translate(community.name) }} posseeix tots els drets de propietat intel·lectual i/o industrial dels Serveis.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} i els seus proveïdors mantindran la propietat de tot dret, títol i interès
        incloent, de manera no limitativa, tots els drets de propietat intel·lectual i/o industrial (en virtut
        del definit més endavant) relacionats amb els Serveis i la Solució, així com de qualsevol feina
        millora derivats d'aquesta, incloent, sense ànim limitatiu, qualsevol programari, tecnologia, informació,
        contingut, materials, directrius i documentació. Ni els Clients ni els Usuaris adquiriran dret,
        títol, interès o cap contingut, exceptuant els drets d'ús limitat establerts de forma expressa
        a les presents Condicions i/oa les Condicions Particulars. Tot dret que no es concedeixi
        expressament en aquest document es considerarà no atorgat.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} no és propietari del contingut de tercers utilitzat com a part dels Serveis,
        inclòs el contingut de les comunicacions que apareixen als Serveis. El títol i els drets de
        propietat intel·lectual i/o industrial del contingut a què s'accedeix a través dels Serveis pertanyen
        al propietari del contingut en qüestió i poden estar protegits per la llei de propietat intel·lectual o
        altres lleis aplicables.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Client accepta no dur a terme ni permetre a altres que duguin a terme les accions següents: (i)
        adaptar, traduir o modificar la Solució; (ii) desxifrar, descompilar, desmuntar, aplicar enginyeria
        inversa o un altre tipus d'intent de descobrir el codi font o d'objectes del programari de la Solució,
        llevat que les lleis aplicables prohibeixin aquestes limitacions; (iii) copiar la Solució; (iv) utilitzar els
        Serveis per a activitats d'alt risc; ni (v) atorgar sota llicència, prestar, vendre, llogar o
        arrendar els serveis o qualsevol component d'aquests
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Qualsevol codi font de tercers inclòs en cadascuna de les aplicacions dels Serveis només podrà
        utilitzar-se conjuntament amb aquesta aplicació dels serveis, l'ús dels quals estarà subjecte a les presents
        Condicions i/o a les Condicions Particulars.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        QUEDA PROHIBIT UTILITZAR, COPIAR, TRANSFERIR I MODIFICAR LA SOLUCIÓ (INCLÒS ELS DRETS DE PROPIETAT
        INTEL·LECTUAL I/O INDUSTRIAL SOBRE LA MATEIXA) O QUALSEVOL PART DE LA MATEIXA, EXCEPTE EN ELS CASOS EN què LES
        PRESENTS CONDICIONS HO PERMETEN DE FORMA EXPRESSA.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Marques i títols distintius
      </h3>
      <b-card-text data-v-35bd2db0="">
        Als efectes de les presents Condicions i de les Condicions Particulars que puguin ser aplicables,
        s'entenen per “Marques i Títols Distintius” els noms comercials, marques comercials, marques de
        servei, logotips, noms de domini i altres elements de marca distintius de cada part,
        respectivament, protegits en cada moment per aquesta part. Cap de les parts pot mostrar o
        utilitzar els Marques i Títols Distintius de l'altra més enllà del permès en aquestes Condicions i/o en
        les Condicions Particulars sense el consentiment previ per escrit de laltra part. Excepte a la
        mesura que s'indiqui expressament, cap de les parts concedirà ni l'altra adquirirà cap
        dret, títol ni interès inclòs, sense limitació, qualsevol llicència implícita de qualsevol Marques i
        Títols Distintius de la primera part. Tots els drets que no es concedeixin expressament es
        consideraran no atorgats. Tot ús dels Marques i Títols Distintius del Client per part de
        {{ translate(community.name) }}, es realitzarà en benefici del Client; i tot ús dels Marques i Títols
        distintius de {{ translate(community.name) }} per part del Client, es realitzarà en benefici de
        {{ translate(community.name) }}. El Client no infringirà ni col·laborarà amb altres en la infracció de les
        Marques i
        Títols Distintius de {{ translate(community.name) }} o el registre dels mateixos, així com tampoc intentarà
        registrar Marques i Títols Distintius de {{ translate(community.name) }} o noms de domini molt semblants als de
        {{ translate(community.name) }} o que puguin induir a equívocs.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Limitació en lús de Marques i Títols Distintius
      </h3>
      <b-card-text data-v-35bd2db0="">
        Les parts podran revocar el dret de l'altra part a fer servir les Marques i els Títols Distintius d'acord amb
        aquestes Condicions mitjançant avís per escrit a laltra part, que haurà de cessar immediatament en el seu
        ús.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Programari de codi obert (Open Source Software) i biblioteques de tercers
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Web i la Solució poden incloure eines de programari de codi obert o biblioteques de tercers.
        El programari de codi obert i/o les biblioteques de tercers es regiran pels seus propis termes i
        condicions i l'Usuari estarà subjecte als termes i condicions de la llicència corresponent a
        relació amb lús del programari o biblioteques. L'Usuari accepta complir totes les esmentades
        llicències.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        CONFIDENCIALITAT
      </h2>
      <b-card-text data-v-35bd2db0="">
        Cadascuna de les parts (i) protegirà la informació confidencial de l'altra part amb les mateixes mesures
        de protecció estàndard que utilitza per protegir la seva pròpia informació confidencial; i (ii) no divulgarà
        la informació confidencial, excepte als empleats i representants, en cas exclusiu que sigui
        necessari i que hagin acceptat per escrit mantenir les obligacions de confidencialitat. Cadascuna de
        les parts (i tots els empleats i representants a qui s'ha revelat informació confidencial)
        ha d'utilitzar la informació confidencial únicament per exercir els seus drets i complir les obligacions
        establertes en aquestes Condicions i/o a les Condicions Particulars; emprant mesures raonables per
        protegir-la. Cadascuna de les parts és responsable de les accions dels seus empleats i representants que
        infringeixin aquest apartat.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Excepcions
      </h3>
      <b-card-text data-v-35bd2db0="">
        No es considera informació confidencial aquella informació (a) que ja sigui coneguda pel destinatari,
        (b) que es faci pública sense responsabilitat del destinatari, (c) que el destinatari generi de forma
        independent o (d) que un tercer li transmeti legítimament.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Divulgació necessària
      </h3>
      <b-card-text data-v-35bd2db0="">
        Cadascuna de les parts pot divulgar la informació confidencial de l'altra part quan sigui requerit
        per la llei, encara que, si la llei ho permet, només després que (i) faci tots els esforços raonables
        per informar l'altra part; i (ii) concediu a l'altra part l'oportunitat de recusar la divulgació.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        PUBLICITAT
      </h2>
      <b-card-text data-v-35bd2db0="">
        El Client i l'Usuari accepten no fer cap tipus d'anunci públic en relació amb la
        existència o el contingut de les presents Condicions i/o les Condicions Particulars sense la prèvia
        autorització per escrit per part de {{ translate(community.name) }}. {{ translate(community.name) }} podrà (i)
        incloure les
        Marques i Títols Distintius del Client en presentacions, materials de màrqueting i llistes de clients,
        que inclouen, sense limitació, llistes de clients publicades als llocs web de {{ translate(community.name) }} i
        captures de pantalla de la implementació dels serveis per part del Client; i (ii) emetre un anunci
        públic en relació amb lexistència o el contingut daquestes Condicions. A petició del Client,
        {{ translate(community.name) }} proporcionarà aquesta mostra d'aquest ús o anunci
      </b-card-text>
      <h2 data-v-35bd2db0="">
        DURACIÓN DE LOS SERVICIOS Y RENOVACIÓN
      </h2>
      <h2 data-v-35bd2db0="">
        DURADA DELS SERVEIS I RENOVACIÓ
      </h2>
      <h3 data-v-35bd2db0="">
        Durada dels Serveis
      </h3>
      <b-card-text data-v-35bd2db0="">
        El termini de cada Servei serà l'especificat a l'Ordre de Compra corresponent. Llevat que a la
        Ordre de compra s'indiqui el contrari, els serveis es renovaran automàticament per períodes
        addicionals equivalents al Període de Llicència de l'Ordre de Compra inicial o un any (el que sigui més
        curt), tret que qualsevol de les parts ho comuniqui a l'altra part per escrit amb una antelació
        mínima de trenta (30) dies abans de la data final del Període de Llicència vigent.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Llevat del que disposa expressament l'Ordre de Compra corresponent, en la renovació dels serveis
        contractats inicialment sota condicions promocionals s'aplicarà el preu tarifa de
        {{ translate(community.name) }}
        vigent en el moment de la renovació corresponent.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Sense perjudici de l'anterior, qualsevol renovació en què el volum d'usuaris dels serveis
        contractats o la durada de qualsevol d'ells sigui inferior al Període de Llicència anterior pot donar
        com a resultat un nou preu per Usuari i Servei, sense tenir en compte el preu per Usuari del
        Període de llicència anterior.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Actualització i revisió de preus
      </h3>
      <b-card-text data-v-35bd2db0="">
        En cas de renovació dels Serveis contractats, {{ translate(community.name) }} podrà actualitzar el preu de
        aquests Serveis a l'alça sense cap preavís en una quantitat equivalent al resultant d'aplicar el
        índex dincrement del cost de vida als preus aplicables durant el Període de Llicència anterior. A
        aquests efectes, es tindrà en compte l'índex d'increment del cost de vida del país a la legislació del qual
        queden subjectes les presents condicions de conformitat amb l'annex I de les mateixes.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} podeu revisar els seus preus per al següent Període de Llicència dels Serveis
        per
        damunt dels imports resultants del paràgraf anterior, sempre que hi hagi notificació per email al
        Client almenys trenta (30) dies abans de l'inici del Període de Llicència dels Serveis en què es
        cal aplicar aquesta revisió de preus.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Devolucions i prorrateig
      </h3>
      <b-card-text data-v-35bd2db0="">
        En cap cas es prorratejaran períodes de llicència i no es realitzaran devolucions dels imports ja
        pagats pel Client.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        PERÍODE DE VIGÈNCIA I TERMINACIÓ DE LES CONDICIONS
      </h2>
      <h3 data-v-35bd2db0="">
        Període de vigència de les Condicions
      </h3>
      <b-card-text data-v-35bd2db0="">
        Aquestes condicions tenen validesa des de la seva acceptació per primera vegada pel client o l'usuari o l'ús
        de Serveis gratuïts per l'Usuari d'acord amb el que indica la condició 1 d'aquestes Condicions i
        continua fins que tots els serveis hagin expirat o hagin estat cancel·lats d'acord amb el que indica
        aquestes Condicions. Sense perjudici de l'anterior, les condicions 7, 8, 9, 12 i 16, i els apartats de la
        condició 3 que per la seva naturalesa hagin de seguir vigents, seguiran vigents una vegada finalitzat el període
        de vigència de les Condicions.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        En el cas de les proves gratuïtes, les notificacions emeses a través dels serveis que indiquen el
        nombre de dies restants de la prova gratuïta constitueixen l'avís per {{ translate(community.name) }} de
        terminacióde la vigència daquestes Condicions.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Qualsevol incompliment de les obligacions contretes pel Client en virtut de les presents
        Condicions incloent, però no limitant-se a, l'impagament, pagament o ús no autoritzat de la Solució,
        programari, producte o dels Serveis de {{ translate(community.name) }}, suposarà un incompliment material de les
        presents Condicions. En cas d'incompliment d'aquestes Condicions per part del Client,
        {{ translate(community.name) }}, sota criteri propi, podrà suspendre o cancel·lar la prestació dels Serveis al
        Client, suspendre els Usuaris i vinculats al Client i les contrasenyes.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Terminació per incompliment
      </h3>
      <b-card-text data-v-35bd2db0="">
        Cadascuna de les parts pot suspendre els Serveis i donar per acabades aquesta Condicions si (i) l'altra
        part incompleix de forma substancial les presents Condicions i no esmena l'incompliment abans de 30
        dies després de la recepció de la notificació per escrit de la part no incomplidora, (ii) l'altra
        part cessa les operacions empresarials o es veu sotmesa a procediments d'insolvència i dits
        procediments no es desestimen abans de noranta dies o (iii) l'altra part incompleix les presents
        Condicions de forma substancial més de dues vegades, sense perjudici que s'esmenin aquests incompliments.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Efectes de la terminació
      </h3>
      <b-card-text data-v-35bd2db0="">
        En cas de terminació (i) els drets atorgats per una banda a l'altra cessaran amb efecte immediat
        (a excepció dels drets atorgats en virtut de les condicions indicades a l'apartat 10.1.), (ii)
        {{ translate(community.name) }} proporcionarà al Client accés a les Dades, de la seva propietat, així com la
        possibilitat de sol·licitar-ne l'exportació, durant un període de trenta (30) dies i de
        conformitat amb els preus de {{ translate(community.name) }} vigents en aquest moment per als Serveis
        aplicables,
        en cas de ser ells necessaris, (iii) després del mateix període de trenta (30) dies una vegada finalitzats els
        serveis, {{ translate(community.name) }} eliminarà les Dades en els termes indicats a la Política de
        Privadesa, (iv) cadascuna de les parts realitzarà immediatament tots els esforços raonables per
        tornar o destruir qualsevol altra informació confidencial de l'altra part, si així se sol·licita, i (v) no
        eximirà al Client del pagament dels Imports deguts i no abonats a {{ translate(community.name) }} fins a la fi
        del
        Període de llicència o de qualsevol de les seves pròrrogues
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Així mateix, {{ translate(community.name) }} pot cancel·lar un compte gratuït en qualsevol moment sense
        notificació
        prèvia a la seva discreció sencera. El Client accepta que {{ translate(community.name) }} no serà responsable
        davant del
        Client ni davant de tercers per motiu d'aquesta cancel·lació. El Client serà l'únic responsable de
        exportar les Dades del compte gratuït abans de la finalització de l'accés a aquest per part del
        Client per qualsevol motiu. Tot i això, en el supòsit que sigui {{ translate(community.name) }} qui
        finalitzi el compte gratuït a la seva discreció, li donarà oportunitat al Client per recuperar
        prèviament les sevesDades
      </b-card-text>
      <h2 data-v-35bd2db0="">
        DECLARACIONS, GARANTIES I LIMITACIÓ DE RESPONSABILITAT
      </h2>
      <h3 data-v-35bd2db0="">
        Declaracions i garanties
      </h3>
      <b-card-text data-v-35bd2db0="">
        Cada part manifesta que disposa de la capacitat jurídica necessària per subscriure les presents
        Condicions. Cada part garanteix que complirà totes les lleis i normatives aplicables a la prestació
        oa l'ús dels Serveis, segons correspongui. El Client garanteix que complirà totes les lleis,
        regulacions i ordenances aplicables o relacionades amb l'ús que faci dels serveis i que tota la
        informació facilitada a {{ translate(community.name) }} és veraç i certa.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Garanties limitades
      </h3>
      <b-card-text data-v-35bd2db0="">
        En la mesura que la llei ho permeti, llevat que quedi expressat altrament en les presents
        Condicions, cap de les parts ofereix cap altra garantia de cap tipus, implícita ni explícita,
        obligatòria ni d'una altra classe incloses, sense limitar-s'hi, les garanties de comerciabilitat, adequació
        per a una finalitat particular i no infracció. {{ translate(community.name) }} no es fa responsable del
        contingut ni de la
        informació a què es pugui accedir a través dels Serveis.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Client reconeix i accepta que cadascun dels serveis pot presentar errors, defectes i altres
        problemes que podrien provocar una fallada del sistema. En conseqüència, els Serveis, inclòs tot el
        contingut, programari (inclosa qualsevol actualització o modificació del mateix), funcions, materials i
        informació posats a disposició o als quals s'accedeixi a través dels serveis, així com tota
        documentació que els acompanyi, es proporcionen “tal qual”, per la qual cosa tot ús que se'n faci serà
        responsabilitat única del Client. {{ translate(community.name) }} i els seus proveïdors d'aplicacions integrades
        amb
        la Solució no ofereix garantia de cap tipus, implícita ni explícita, obligatòria ni d'una altra classe
        incloses, sense limitació, les garanties de comerciabilitat, adequació per a un fi particular i de no
        infracció. {{ translate(community.name) }} no assumeix cap tipus de responsabilitat derivada de l'ús correcte
        dels
        Serveis. {{ translate(community.name) }} i els seus proveïdors d'aplicacions integrades amb la Solució no emeten
        declaracions relacionades amb cap contingut ni informació accessible a través dels serveis.
        {{ translate(community.name) }} no emet cap declaració que {{ translate(community.name) }}, o cap tercer,
        publicarà
        actualitzacions o millores dels serveis. {{ translate(community.name) }} no garanteix que les funcions incloses
        als Serveis no es veuran interrompudes ni que no tinguin errors.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Limitació de responsabilitat
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} NI ELS SEUS PROVEÏDORS D'APLICACIONS INTEGRADES AMB LA SOLUCIÓ NO ES
        RESPONSABILITZARAN
        DE CAP DANY DIRECTE O INDIRECTE INCLOSOS, SENSE LIMITACIONS, ELS DANYS CAUSATS PER LA INTERRUPCIÓ DEL
        ÚS O PER LA PÈRDUA DE DADES O LA PRESÈNCIA DE DADES IMPRECISOS O DANYATS, PÈRDUA DE BENEFICIS O DESPESES
        DEL PROVEÏMENT DE BIENÈS SERVEIS SUBSTITUTIUS, SIGUI QUIN SIGUI LA SEVA CAUSA, INCLOSOS, SENSE LIMITACIÓ, EL
        ÚS, MAL ÚS, INCAPACITAT D'ÚS O ÚS INTERROMPUT, NI SOTA CAP TEORIA DE RESPONSABILITAT INCLOSOS,
        SENSE LIMITACIÓ, CONTRACTES O AGRAVIS I INDEPENDENTMENT DE SI {{ translate(community.name) }} TENIA O HAURIA
        D'HAVER-HI
        TINGUT CONEIXEMENT O HAVER ESTAT ADVERTIT DE LA POSSIBILITAT D'AQUESTS DANYS, AL MARGE DE SI QUALSEVOL
        REMEI ESPECIFICAT EN LES PRESENTS CONDICIONS NO COMPLIERA LA SEVA FINALITAT ESSENCIAL; O DE CAP
        RECLAMACIÓ QUE AL·LEGEU DANYS DERIVATS D'ERRORS, OMISSIONS O ALTRES IMPRECISIONS DELS SERVEIS O LES
        PROPIETATS DELS MATEIXOS.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Limitació de les responsabilitats indirectes
      </h3>
      <b-card-text data-v-35bd2db0="">
        CAP DE LES PARTS SERÀ RESPONSABLE PER DANY EMERGENT, LUCRE CESSANT NI DANY INDIRECTE.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Limitació de limport per responsabilitat
      </h3>
      <b-card-text data-v-35bd2db0="">
        Les parts acorden limitar la responsabilitat derivada de qualsevol dany o incompliment imputable a
        {{ translate(community.name) }} a l'import efectivament pagat pel Client segons el que estipulen aquestes
        Condicions, durant els sis (6) mesos anteriors a l'esdeveniment que ha donat lloc a aquesta responsabilitat.
        {{ translate(community.name) }} no atorga cap garantia per l'ús de serveis que no estiguin subjectes a
        contraprestació o durant el període de prova.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        13. {{ translate(community.name) }} COM ENCARREGAT DEL TRACTAMENT DE LES “DADES”
      </h2>
      <b-card-text data-v-35bd2db0="">
        La captació i tractament de dades personals realitzades a través del nostre Lloc Web ia través de la
        prestació dels Serveis aplicables a les presents Condicions es regeix pel que disposa la nostra
        Política de Privadesa i Política de Cookies que queden incorporades als presentsTermes i
        Condicions.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} només és responsable quant al tractament de les dades de contacte i facturació
        dels Clients.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Pel que fa a les Dades, als efectes oportuns, detallem a continuació la informació
        bàsica sobre protecció de dades:
      </b-card-text>
      <div data-v-35bd2db0="" class="table">
        <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
          <div data-v-35bd2db0="" class="col-lg-3">
            Encarregat del Tractament
          </div>
          <div data-v-35bd2db0="" class="col-lg-9">
            Empresa {{ translate(community.name) }} Technologies SL
          </div>
        </div>
        <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
          <div data-v-35bd2db0="" class="col-lg-3">
            Finalitat del Tractament
          </div>
          <div data-v-35bd2db0="" class="col-lg-9">
            Gestionar la prestació de serveis
          </div>
        </div>
        <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
          <div data-v-35bd2db0="" class="col-lg-3">
            Base jurídica
          </div>
          <div data-v-35bd2db0="" class="col-lg-9">
            La Solució de {{ translate(community.name) }} permet als Clients processar les “Dades”, per la qual cosa la
            base
            jurídica perquè l'empresa contractant tracti aquestes dades com a encarregat del tractament és la
            necessitat de prestar els Serveis d'acord amb les presents Condicions Generals d'Ús i
            Contractació (art. 6.1 b) GDPR).
          </div>
        </div>
        <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
          <div data-v-35bd2db0="" class="col-lg-3">
            Destinataris
          </div>
          <div data-v-35bd2db0="" class="col-lg-9">
            Es podran cedir dades a altres empreses del grup {{ translate(community.name) }}, en la mesura necessària per
            prestar els serveis i per al compliment d'obligacions legals. <br>data-v-35bd2db0="">
            Es poden fer servir encarregats de tractament fora de la UE, en països amb les garanties adequades o
            complint amb els requeriments legals a cada moment.
          </div>
        </div>
        <div data-v-35bd2db0="" class="row" style="border-top: 1px solid black;">
          <div data-v-35bd2db0="" class="col-lg-3">
            Drets
          </div>
          <div data-v-35bd2db0="" class="col-lg-9">
            Accedir, rectificar i suprimir les seves dades, sol·licitar-ne la portabilitat, oposar-se al
            tractament i sol·licitar-ne la limitació.
          </div>
        </div>
      </div>
      <h2 data-v-35bd2db0="">
        SUPORT
      </h2>
      <b-card-text data-v-35bd2db0="">
        La Llicència d'Usuari inclou un servei de suport bàsic a prestar per {{ translate(community.name) }} al Client
        conforme al servei suport bàsic que correspongui a l'edició o paquet de la Solució que el Client
        hagi contractat (d'ara endavant el “Servei de Suport”).
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        A aquests efectes, el client i l'administrador del client es poden posar en contacte amb els serveis de
        assistència tècnica proporcionats per {{ translate(community.name) }}, mitjançant els canals habilitats per a
        això
        en cada moment, resultant en un cas (“tiquet”) que serà gestionat per {{ translate(community.name) }} fins al
        seu
        tancament.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} atendrà i respondrà a les incidències o consultes enviant missatges de correu
        electrònic a l'adreça de correu electrònic i/o contactant al número de telèfon que el Client
        tingueu assignats al vostre Compte d'Usuari.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Servei de Suport és de caràcter correctiu, és a dir, està orientat a la correcció dincidències
        en el funcionament del producte estàndard, és a dir, la versió base de la Solució, comuna a qualsevol
        contractació per part d'un client, i sobre la qual s'efectuaran les personalitzacions contractades per
        cada client (d'ara endavant el “Producte Standard”). El Servei de Suport no gestiona incidències
        produïdes a les personalitzacions particulars del client realitzades pels equips de serveis
        professionals de {{ translate(community.name) }} o els seus partners.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} donarà assistència tècnica pel que fa a les dues últimes versions aprovades de
        la
        Solució.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        A més, {{ translate(community.name) }} podrà descatalogar parts o versions de la Solució prèvia comunicació a
        els clients amb antelació suficient i raonable perquè puguin adaptar-se als canvis. La
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        descatalogació duna part o versió de la Solució suposa que
        {{ translate(community.name) }} no donarà suport ni realitzarà accions de manteniment evolutiu o correctiu sobre
        aquesta part o versions.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Client podrà contractar {{ translate(community.name) }} serveis de suport més avançats, que estaran subjectes
        a les presents Condicions Generals ia les Condicions Particulars que els siguin aplicables.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        SERVEIS PROFESSIONALS
      </h2>
      <b-card-text data-v-35bd2db0="">
        Les capacitats estàndard de la Solució poden ser esteses per a un client concret per consultors
        especialitzats en la tecnologia de la Solució, ja siguin consultors de {{ translate(community.name) }} o
        partners
        certificats, donant com a resultat un producte estès que consta del Producte Estàndard més els seus
        personalitzacions.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Les personalitzacions, per ser parts de la Solució desenvolupades únicament per a un client, estan
        subjectes a les condicions de garantia, manteniment o suport estipulades per l'implantador (prestador
        serveis professionals) que les hagi realitzat. El prestador de serveis professionals podrà ser
        {{ translate(community.name) }} directament o qualsevol dels seus Partners certificats.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        Cada implementador de la Solució definirà els termes i condicions aplicables a la prestació de
        serveis professionals en un document específic.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        INDEMNITZACIÓ
      </h2>
      <b-card-text data-v-35bd2db0="">
        El Client haurà d'indemnitzar i mantenir indemne a {{ translate(community.name) }} i les seves sucursals,
        filials,
        empreses del grup, proveïdors, directors, accionistes, empleats, col·laboradors i agents, de qualsevol
        reclamació o demanda d'un tercer, en relació amb (i) les dades, (ii) qualsevol infracció per part
        del Client dels Drets de propietat intel·lectual i/o industrial d'un tercer o (iii) un ús dels
        Serveis per part del Client o de l'Usuari que infringeixi els termes i les condicions d'ús establertes
        a les presents Condicions Generals.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        El Client haurà d'indemnitzar {{ translate(community.name) }} per les reclamacions derivades de qualsevol
        incompliment sorgit de la combinació dels serveis amb qualsevol altre producte, servei, maquinari
        o procés empresarial
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        En cap cas {{ translate(community.name) }} tindrà cap obligació ni cap responsabilitat, segons s'estableix a
        aquest apartat, derivades del (i) ús dels Serveis o elements de marca de {{ translate(community.name) }} de
        forma modificada o en combinació amb altres materials no facilitats per {{ translate(community.name) }} i (ii)
        contingut, informació o dades proporcionades pel Client, {{ translate(community.name) }}, Usuaris o altres
        tercers.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        POSSIBLES INFRACCIONS
      </h2>
      <h3 data-v-35bd2db0="">
        Reparar, substituir o modificar
      </h3>
      <b-card-text data-v-35bd2db0="">
        Si {{ translate(community.name) }} té sospites raonables que els Serveis infringeixen els Drets de
        propietat intel·lectual i/o industrial d'un tercer, {{ translate(community.name) }} (a) obtindrà l'autorització
        de
        ús per part del tercer per al Client, a càrrec de {{ translate(community.name) }}, perquè pugui seguir usant
        els serveis, (b) proporcionarà un substitut funcionalment equivalent que no els infringeixi o (c)
        modificarà els serveis perquè la infracció no es torni a produir.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Suspensió o rescissió
      </h3>
      <b-card-text data-v-35bd2db0="">
        Si {{ translate(community.name) }} considera que les opcions anteriors no són raonables, podeu suspendre o
        rescindir l'ús dels serveis afectats. Si {{ translate(community.name) }} rescindeix els serveis afectats,
        haurà de proporcionar una devolució prorratejada dels imports no meritats ja pagats pel client
        aplicables al període següent a la rescissió del Servei esmentat.
      </b-card-text>
      <h2 data-v-35bd2db0="">
        ALTRES DISPOSICIONS
      </h2>
      <h3 data-v-35bd2db0="">
        Programari de tercers
      </h3>
      <b-card-text data-v-35bd2db0="">
        A efectes aclaridors es fa constar que aquestes Condicions són aplicables al Client i/o al
        Usuari pel que fa a l'ús per part d'aquests de qualsevol funcionalitat permesa per la solució
        que sigui proporcionada per un tercer proveïdor. És a dir, les limitacions dús, obligacions i
        prohibicions contingudes en les presents Condicions pel que fa a la Solució, ho són pel que fa a
        qualsevol programari de tercer inclòs en aquesta.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Modificació
      </h3>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} es reserva el dret de canviar o modificar qualsevol de les presents
        Condicions, així com tota política que regeixi els Serveis, en qualsevol moment, mitjançant la publicació
        de les condicions a <a data-v-35bd2db0="" href=" http://nectios.com "> http://nectios.com </a>o
        qualsevol altra URL que {{ translate(community.name) }} proporcioni. El Client és responsable de consultar amb
        regularitat les possibles actualitzacions de les presents Condicions. Tots els canvis o modificacions
        que es realitzin seran vinculants (i) quan les dues parts els acceptin per escrit, (ii) quan el Client
        accepteu online l'actualització dels mateixos o si (iii) una vegada que {{ translate(community.name) }}
        actualitzi dites
        termes, el Client continua fent un ús continuat dels Serveis.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Comunicacions
      </h3>
      <b-card-text data-v-35bd2db0="">
        Totes les notificacions i comunicacions que siguin procedents, derivades de la relació contractual objecte de
        les
        presents Condicions s'enviaran per escrit, podent enviar-se fins i tot per correu electrònic sempre
        que es pugui deixar constància de la recepció. A aquests efectes el Client podrà enviar les comunicacions
        a la direcció de
        <a data-v-35bd2db0="" href="mailto:hello@nectios.com.">hello@nectios.com.</a>
        {{ translate(community.name) }} podrà enviar les comunicacions a l'adreça electrònica que el client hi hagi
        notificat a la documentació relacionada amb la venda de la Solució. Les parts podran vàries les
        adreces facilitades, comunicant-ho a l'altra per escrit
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Cessió
      </h3>
      <b-card-text data-v-35bd2db0="">
        El Client no podrà cedir ni transferir la seva posició contractual, ni els drets i les obligacions assumits
        en virtut de les presents Condicions, sense el consentiment per escrit de {{ translate(community.name) }}, i en
        tot cas s'haurà de complir el següent, que (a) el beneficiari accepti per escrit obligar-se a les
        condicions de les presents Condicions i (b) la part que efectua la cessió continua sent responsable de
        les obligacions contretes mitjançant les presents Condicions abans de la dita cessió. Qualsevol altre
        intent de cessió es considera nul de ple dret.
      </b-card-text>
      <b-card-text data-v-35bd2db0="">
        {{ translate(community.name) }} tindrà dret a prestar els serveis directament oa través de qualsevol de les
        companyies que formen part del grup {{ translate(community.name) }}, llistades a l'Annex I, llistat que està
        actualitzat en tot moment a la política de privadesa de {{ translate(community.name) }}, havent d'acceptar el
        Client la prestació dels serveis esmentats per les filials com si haguessin estat prestats per
        {{ translate(community.name) }}.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Força major
      </h3>
      <b-card-text data-v-35bd2db0="">
        Cap de les parts serà responsable per l'incompliment de les obligacions establertes a les
        presents Condicions, sempre que estigui causat per una circumstància aliena al control raonable de
        l'altra part com, per exemple, desastres naturals, actes de guerra o terrorisme, disturbis, conflictes
        laborals, actuacions del Govern i errors dInternet.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Vigència de les condicions
      </h3>
      <b-card-text data-v-35bd2db0="">
        Si alguna disposició de les presents Condicions es considera inaplicable, les disposicions restants
        romandran en vigor.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Inexistència de representació
      </h3>
      <b-card-text data-v-35bd2db0="">
        Les parts contractants són independents i les presents Condicions no constitueixen cap
        representació, associació o empresa conjunta.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Empresa contractant, llei i jurisdicció aplicable
      </h3>
      <b-card-text data-v-35bd2db0="">
        A Annex I es detallen les empreses contractants per part del grup
        {{ translate(community.name) }}, així com la llei i jurisdicció aplicable a les presents Condicions en funció
        del
        domicili on es trobe el Client.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Esmenes
      </h3>
      <b-card-text data-v-35bd2db0="">
        Tota esmena s'ha de fer per escrit i indicar expressament que es tracta d'una esmena de les
        presents Condicions.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Acord complet
      </h3>
      <b-card-text data-v-35bd2db0="">
        Les presents Condicions i tots els documents a què es fa menció o remissió en aquestes
        constitueixen la totalitat de l'acord entre les parts respecte a la matèria objecte de les mateixes i
        substitueixen tots els acords previs o actuals sobre aquesta matèria.
      </b-card-text>
      <h3 data-v-35bd2db0="">
        Interpretació de condicions en conflicte
      </h3>
      <b-card-text data-v-35bd2db0="">
        En cas que hi hagi algun conflicte entre les Condicions i qualsevol versió anterior de les mateixes, i
        llevat que s'indiqui altrament, prevaldran les disposicions d'aquestes Condicions.
      </b-card-text>
    </div>
    <div v-if="currentLocale === 'en'">
    <h2>Legal information</h2>

<p>These General Conditions of Use and Contracting (hereinafter the &laquo;Conditions&raquo;) of COPERNIC TECHNOLOGIES S.L., a company of Spanish nationality, with registered office in Barcelona, ​​Dr Letamendi, 37, 3-2, provided with C.I.F. B67073130 and registered in the Barcelona Companies Registry in volume 46,104, folio 198, page B-509,617 (hereinafter, &laquo;COPERNIC TECHNOLOGIES&raquo; or the &laquo;Company&raquo;), regulate the use of the services offered through the Nectios solution ( hereinafter, &laquo;Nectios&raquo; or &laquo;the Solution&raquo;) that COPERNIC TECHNOLOGIES makes available to its users and customers, either through Web access, on the Internet portal 'https://nectios.com' (hereinafter, the &ldquo;Website&rdquo; or the &ldquo;Web&rdquo; interchangeably), through iOS or Android, online or offline, as well as through the desktop application.</p>

<p>The use of the Web and Nectios attributes the condition of user (hereinafter, the &laquo;User&raquo;) and the contracting of the Services, as defined below, attributes the condition of client (hereinafter, the &laquo;Client&raquo;) and imply acceptance of these Conditions. Some services or functionalities of the Solution may be subject to particular conditions that, where appropriate, modify or complement these Conditions and that will be understood to be accepted by the User and/or the Client at the beginning of the provision of said service or of the use of the corresponding functionality.</p>

<p>COPERNIC TECHNOLOGIES reserves the right to modify, at any time and without prior notice, the content and configuration of the Website and the Solution, as well as the services offered. Users and Clients must carefully read these Conditions. By clicking indicating your acceptance or by accepting a purchase order for the Services, as these are defined below, the Client and the User accept these Conditions that govern the use of the Services. Likewise, the registration or use of the Solution implies full acceptance of these Conditions, as well as the privacy policies of Nectios included in 'https://nectios.com/privacy-policy/' (hereinafter the &raquo; Privacy Policy&raquo;).</p>

<h2>Services</h2>

<p>COPERNIC TECHNOLOGIES makes available to the Client, through the Solution, a set of commercial team management services. The Services are offered online through a web platform 'https://nectios.com/''. The Services are offered in various editions and packages that may differ significantly in functionality. The Services may not be accessed or used for the purpose of monitoring their availability, performance, or functionality, or for any other competitive or benchmarking purposes.</p>

<h2>Administration of services by the client</h2>

<p>Customer will be provided with a name and password to be used for the purposes of managing Customer&rsquo;s accounts. The Client has the responsibility to maintain the confidentiality of said password and account, for which it will designate the employees who will have authorization to access the account and will limit the scope of said authorization to the fulfillment of the assigned tasks and for all the activities that are made under the Client&rsquo;s account. The Client undertakes to immediately notify COPERNIC TECHNOLOGIES (also Nectios) of any unauthorized use of or access to the Services, the Solution, the Client&rsquo;s password or account, or any other breach of security. Nectios cannot and will not be liable for any loss or damage arising from Customer&rsquo;s breach of security obligations. The Client acknowledges and accepts that COPERNIC TECHNOLOGIES (also Nectios) will not be responsible in any case and under any circumstances for any action or omission by the Client or any User, including damages of any kind derived from said actions or omissions. .</p>

<h2>Accounts</h2>

<p>When creating an account with us, you must provide us with accurate, complete, and current information at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service. You are responsible for safeguarding the password you use to access the Service and for any activities or actions performed under your password, whether with our Service or a third-party service. You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>

<h2>Compliance with the conditions</h2>

<p>The Client and the User undertake to use the Services in accordance with the terms established in these Conditions. Nectios may offer, as it deems appropriate, additional applications, features or functions through the Services, the use of which may be subject to Particular Conditions that must be accepted by the Client and, where appropriate, by the User.</p>

<h2>Intellectual property</h2>

<p>The Service and its original content, features and functionality are and remain the exclusive property of Copernic Technologies (also Nectios) and its licensors.</p>

<h2>Links to other websites</h2>

<p>Our Service may contain links to third party websites or services that are not owned or controlled by Copernic Technologies (also Nectios). Copernic Technologies (also Nectios) has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. Furthermore, you acknowledge and agree that Nectios shall not be liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, goods or services available on or through such websites. or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party website or service that you visit.</p>

<h2>Payments and invoices</h2>

<p>All payments will be made in the manner indicated in the purchase order for services accepted by the Client (hereinafter, the &laquo;Purchase Order&raquo;). The Client will make the payment of the amounts indicated in the Purchase Order (hereinafter, the &laquo;Amounts&raquo;). The Amounts, once invoiced, must be paid at the beginning of the period in which the Client receives the Services and with the frequency agreed in the Purchase Order. Payment obligations cannot be canceled nor can amounts already paid be reimbursed. Customer is obligated to pay for all requested user licenses during the entire license period set forth in the Purchase Order (the &laquo;License Period&raquo;), whether in use or not.</p>

<p>The Client agrees to provide complete and accurate billing and contact information to COPERNIC TECHNOLOGIES (also Nectios). This information includes company name, checking account number, credit card (if applicable) or other applicable form of payment, mailing address, email address, contact name and phone number. of authorized invoicing, the identification number of the intracommunity value added tax (if applicable) and the data of the Client&rsquo;s administrator.</p>

<p>The Client undertakes to update this information within ten (10) days after any modification. If the contact information you have provided is false, fraudulent or out of date, COPERNIC TECHNOLOGIES (also Nectios) reserves the right to cancel access to the Service, as well as to take any other legal action it deems appropriate. If the Customer considers that their invoice is not correct, they must contact Nectios or its distributor in writing, within a maximum period of seven (7) days from the date of said invoice, to jointly review said invoice and, where appropriate, , to be able to receive a rectification.</p>

<h2>Additional fees</h2>

<p>The data storage space that COPERNIC TECHNOLOGIES (also Nectios) makes available to the Client for free is 1GB in database for the set of User Licenses that the Client has contracted in any edition or package of Nectios and an additional 5MB for each User License of any edition or package of Nectios. If the amount of disk storage required by the Client exceeds the limits established in the previous paragraph, COPERNIC TECHNOLOGIES (also Nectios) reserves the right to charge the Client the storage rate in force at that time. COPERNIC TECHNOLOGIES (also Nectios) will try to notify the Client, to the extent possible, when the average storage used per license reaches approximately ninety percent (90%) of the indicated limit.</p>

<p>However, if Nectios does not notify you of this situation, this does not relieve the Client of its responsibility and obligation to pay the charges for additional data storage. COPERNIC TECHNOLOGIES (also Nectios) reserves the right to establish or modify at any time, its procedure and usual limits in relation to data storage. Nectios will publish its data storage policy and the limits in force at any time on its website. The file storage space that Nectios makes available to the Client for free is 2.5GB for the set of User Licenses that the Client has contracted in any edition or package of Nectios and an additional 250MB for each User License of any edition or package. of Nectios. If the amount of file storage required by the Client exceeds the limits established in the previous paragraph, Nectios reserves the right to charge the Client the storage rate in force at that time.</p>

<p>COPERNIC TECHNOLOGIES (also Nectios) will try to notify the Client, to the extent possible, when the average storage used per license reaches approximately ninety percent (90%) of the indicated limit. However, if Nectios does not notify you of this situation, this does not relieve the Client of its responsibility and obligation to pay the additional expenses of file storage. COPERNIC TECHNOLOGIES (also Nectios) reserves the right to establish or modify at any time, its procedure and usual limits in relation to the storage of files. Nectios will publish its file storage policy and the limits in force at any time on its website.</p>

<h2>Payment slip</h2>

<p>Nectios reserves the right to suspend or terminate these Conditions and, where appropriate, the applicable Particular Conditions and, consequently, access to the Services by the Client if the latter incurs in late payments for a period greater than sixty (60) days from its expiration, will empower Nectios to unsubscribe/suspend the service until the full amount due is collected. After sixty (60) days from the suspension of the service without the Client having made the payment of all the amounts due, the contract will be automatically terminated, without prejudice to the right of Nectios to claim any amount due and/or any damage or harm caused by the Client. Said contractual resolution will entail the elimination of the Data, as these are defined in the Terms and Conditions of Nectios.&nbsp;</p>

<p>Accounts with late payments are subject to a monthly interest of 1.5% applicable on the outstanding balance, in addition to all the expenses necessary for its collection. This interest may not be split and a single day of delay will imply the application of all interest. If the Client or Nectios terminate access to the Service, the Client will be obliged to pay the outstanding balance of their account. Nectios reserves the right to impose a reconnection fee in the event that the Customer has been suspended and has subsequently requested access to the Service.</p>

<h2>Intellectual and/or industrial property rights</h2>

<p>Except for what is expressly established in this document, these Conditions do not guarantee any of the parties any right, implicit or not, over the content or over any intellectual and/or industrial property right of the other party. In this sense, COPERNIC TECHNOLOGIES owns all the intellectual and/or industrial property rights of the Services.</p>

<p>COPERNIC TECHNOLOGIES and its suppliers will retain ownership of all right, title and interest including, but not limited to, all intellectual and/or industrial property rights (as defined below) related to the Services and the Solution, as well as as well as any work or improvements derived from it, including, without limitation, any software, technology, information, content, materials, guidelines and documentation. The Client will not acquire any right, title or interest contained, except for the rights of limited use expressly established in these Conditions. Any rights not expressly granted herein will be deemed not granted. COPERNIC TECHNOLOGIES does not own the content of third parties used as part of the Services, including the content of the communications that appear on the Services. The title and intellectual and/or industrial property rights of the content accessed through the Services belong to the owner of the content in question and may be protected by intellectual property law or other applicable laws. Customer agrees not to do or allow others to do any of the following: (i) adapt, translate, or modify the Solution; (ii) decipher, decompile, disassemble, reverse engineer or otherwise attempt to discover the source or object code of the Solution software, except where such limitations are prohibited by applicable law; (iii) copy the Solution; (iv) use the Services for high-risk activities or (v) license, lend, sell, rent or lease the Services or any component thereof. Any third party source code included in each application of the Services may only be used in conjunction with such application of the Services, the use of which is subject to these Terms.</p>

<p>IT IS PROHIBITED TO USE, COPY, TRANSFER AND MODIFY THE SOLUTION (INCLUDING THE INTELLECTUAL AND/OR INDUSTRIAL PROPERTY RIGHTS OVER THE SAME) OR ANY PART OF THE SOLUTION, EXCEPT AS EXPRESSLY PERMITTED BY THESE TERMS.</p>

<h2>Confidentiality</h2>

<p>Each party will (a) protect the Confidential Information of the other party with the same standard safeguards that it uses to protect its own Confidential Information and (b) will not disclose the Confidential Information, except to employees and agents, in the Only if it is necessary and if they have agreed in writing to maintain confidentiality obligations. Each party (and all employees and agents to whom Confidential Information has been disclosed) must use the Confidential Information solely to exercise its rights and fulfill its obligations under these Terms; using reasonable measures to protect it.</p>

<h2>Termination</h2>

<p>We may terminate or suspend your account immediately, without notice or liability, for any reason, including, without limitation, if you breach the Terms. Upon termination, your right to use the Service will immediately cease. If you wish to cancel your account, you must notify us thirty (30) days in advance by sending an email to the following address: administration@nectios.com. In case of not doing it with the indicated time, the client will be obliged to pay the months that he has not been using the service before his notification. All provisions of the Terms that by their nature should survive termination will survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnification, and limitations of liability.</p>

<h2>Disclaimer</h2>

<p>Your use of the Service is at your own risk. The Service is provided on an &laquo;AS IS&raquo; and &laquo;AS AVAILABLE&raquo; basis. The Service is provided without warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.</p>

<h2>Applicable Law</h2>

<p>These Terms will be governed by and construed in accordance with the laws of Spain, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held by a court to be invalid or unenforceable, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us relating to our Service, and supersede and replace any prior agreements we may have had between us relating to the Service.</p>

<h2>Changes</h2>

<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days&rsquo; notice before any new terms take effect. What constitutes a material change will be determined in our sole discretion. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>

    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import BgHeader from '@/assets/images/placeholders/light/banner-nectios.png';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { getImageResource } from '@/@core/utils/image-utils';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import Service from '@/config/service-identifiers';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  components: {
    SafeImg,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, ToastNotificationsMixin],
  data() {
    return {
      isLoading: false,
      community: { name: this.$t('my-nectios.title') },
      legalText: {},
      legalMenu: [],
      selectedText: 'gdpr',
      text: null,

      from: null,
      path: null,
    };
  },
  computed: {
    currentLocale() {
      return this.$route?.query?.locale || this.$store.state.locale.currentLocale;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    email() {
      return `mailto:newsletter${this.community.mainCommunitySlug}.tech`;
    },
    signUpURL() {
      let url = process.env.VUE_APP_SIGN_UP_URL.replace('{subdomain}', this.community.subdomain || 'app').replace(
        '{slug}',
        this.communitySlug,
      );
      url = `${url}?code=${this.community.language}`;
      return url;
    },
    showRegistrationLink() {
      return this.community.accessibility < 2;
    },
    backgroundStyle() {
      const backgrounds = this.community?.customization?.theme?.login?.backgrounds || [];

      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat !important',
        };
      }

      return null;
    },
    communityLogo() {
      return getImageResource(
        this.community?.customization?.theme?.login?.logo || this.community?.header?.logo_url || this.community?.logoURL || BgHeader,
      );
    },
    brand() {
      return BrandLogo;
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY;
    },
    localeInUrl() {
      return this.$route.query.locale;
    },
  },
  watch: {
    communitySlug() {
      this.updateCommunity();
    },
    setLocale(newLocale) {
      this.$store.dispatch('setLocale', newLocale);
    },
  },
  async created() {
    if (this.communitySlug === 'my') {
      this.legalText = {
        terms_conditions: null,
        privacy_policy: null,
        gdpr: null,
        cookies_policy: null,
      };
      this.legalMenu = Object.keys(this.legalText);
      this.selectedText = 'gdpr';
    } else {
      await this.updateCommunity();
      this.selectedText = 'gdpr';
      await this.fetchpolicy();
    }
    if (this.localeInUrl) {
      this.setLocale(this.localeInUrl);
    } else {
      this.setLocale(this.community.language);
    }
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    async fetchpolicy() {
      this.isLoading = true;
      await this.$store.$service[
        Service.BackendClient
      ].get('communityCustomizations/ext', { params: { communitySlug: this.communitySlug } })
        .then((response) => {
          this.legalText = response.data?.community_customizations;
          this.legalMenu = Object.keys(this.legalText).filter((menu) => menu === 'terms_conditions' || menu === 'privacy_policy' || menu === 'gdpr' || menu === 'cookies_policy');
          this.isLoading = false;
        })
        .catch((e) => {
          this.legalText = e.response.data.community_customizations;
          this.legalMenu = Object.keys(this.legalText);
          this.isLoading = false;
        });
    },
    selectedTitle(word) {
      return this.$t(`legal.${word}`);
    },
    getIcon(legal) {
      const menu = {
        terms_conditions: 'FileTextIcon',
        privacy_policy: 'ShieldIcon',
        gdpr: 'LockIcon',
      };
      return menu[legal];
    },
    async updateCommunity() {
      this.isLoading = true;

      const response = await this.$store.$service[Service.BackendClient].get('login', {
        params: { communitySlug: this.communitySlug },
      });
      this.community = response.data.data;
      /* Community colors */
      if (this.community.community_customizations.theme) {
        const customization = this.community.community_customizations.theme;
        if (customization.primaryColor) {
          document.documentElement.style.setProperty(
            '--primary-color',
            customization.primaryColor,
          );
          let hsl = customization.primaryColor;
          hsl = hsl.substring(4, hsl.length - 1);
          hsl = hsl.split(',');
          document.documentElement.style.setProperty(
            '--primary-color-h',
            hsl[0],
          );
          document.documentElement.style.setProperty(
            '--primary-color-s',
            hsl[1],
          );
          document.documentElement.style.setProperty(
            '--primary-color-l',
            hsl[2],
          );
        }
        if (customization.secondaryColor) {
          document.documentElement.style.setProperty(
            '--secondary-color',
            customization.secondaryColor,
          );
          let hslSecondary = customization.secondaryColor;
          hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
          hslSecondary = hslSecondary.split(',');
          document.documentElement.style.setProperty(
            '--secondary-color-h',
            hslSecondary[0],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-s',
            hslSecondary[1],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-l',
            hslSecondary[2],
          );
        }
      }
      /* Finish colors */
      /*if (this.community.showChatbot === 1) {
        const chatBot = document.createElement('script');
        chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
        document.body.appendChild(chatBot);
      }*/
      if (this.community.showChatbot === 2) {
        const chatBot = document.createElement('div');
        chatBot.innerHTML = this.$store.getters.currentCollective?.customChatbot;
        document.body.appendChild(chatBot);
      }

      this.isLoading = false;
    },
    setLocale(locale) {
      this.$store.dispatch('setLocale', locale);
    },
    translateTranslationTable,
  },
};
</script>
